import { Button, Tabs } from "antd";
import Detail from "./components/detail";
import Recents from "./components/recents";
import Records from "./components/records";
import ajax from "../../assets/js/ajax";
import { useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";

let navigate: NavigateFunction | null = null;

export default () => {
  navigate = useNavigate();
  const params = useLocation().state as {
    tradeNo: string;
  };
  const [data, setData] = useState<any>({});
  const [chainAccountData, setChainAccountData] = useState<any>({});
  const [coreEnterpriseData, setCoreEnterpriseData] = useState<any>({});
  const [documentGroups, setDocumentGroups] = useState([]);
  const getDetail = () => {
    setDocumentGroups([])
    ajax.post({
      url: "/openPlatform/accountsReceivableFinancing/orderDetail",
      data: params,
      self: this,
      success: (res) => {
        setData(res.order ?? {});
        setChainAccountData(res.order.chainAccountData ?? {});
        setCoreEnterpriseData(res.order.coreEnterpriseData ?? {});
        if (res.documentGroups && res.documentGroups.length) {
          setDocumentGroups((data: any) => {
            res.documentGroups.forEach((el: any) => {
              data.push({ ...el });
            });
            return data;
          });
        }
      },
      fail: () => {},
    });
  };

  const onRefreshData = () => {
    getDetail();
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <div>
      <div className="pb-[12]">
        <span className="t-[16] t-[#192530] pr-[4]">应收账款融资订单详情</span>
      </div>
      <div className="bg-[#FFFFFF] px-[10]">
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="基础信息" key="1">
            <Detail
              data={data}
              chainAccountData={chainAccountData}
              coreEnterpriseData={coreEnterpriseData}
              documentGroups={documentGroups}
              onRefreshData={onRefreshData}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="近期应收账款" key="2">
            <Recents realEnterpriseId={data.upstreamEnterpriseId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="近期收款记录" key="3">
            <Records realEnterpriseId={data.upstreamEnterpriseId} />
          </Tabs.TabPane>
        </Tabs>
      </div>
      <div className="text-center mt-[32]">
        <Button
          type="default"
          className="w-160"
          onClick={() => {
            navigate!("/admin/supplyReceives");
          }}
        >
          返回
        </Button>
      </div>
    </div>
  );
};
