import proceedingsLess from "./css/proceedings.less";
import Dgform from "../../components/dg-form";
import { Table, Button, Form, Pagination } from "antd";
import type { FormInstance } from "antd";
import { memo, useEffect, useRef, useState } from "react";
import { itmeType } from "../../types";
import ajax from "../../assets/js/ajax";
import type { ColumnsType } from "antd/es/table";
import { NavigateFunction, useNavigate } from "react-router-dom";
import EmptyPage from "../../components/emptyPage";
import { hasPermission } from "../../assets/js/hasPermission";

let navigate: NavigateFunction | null = null;

interface DataType {
  key: React.Key;
  title: string;
  dataIndex: string;
  align: string;
}

interface baoHan {
  pageNo: number | null; // 页数
  pageSize: number | null; // 条数
  gmtCreateBegin?: string; // 提交开始时间
  gmtCreateEnd?: string; // 提交结束时间
  sortDirection?: string; // 排序方向
  sortField?: string; // 排序字段
  status?: number | null; // 状态
  tradeNo?: string; // 订单编号
  applicantName?: string; // 申请人
  preserveType?: number | null; //保全类型
  courtName?: string; //法院名称
}

export default memo(() => {
  navigate = useNavigate();

  const columns: ColumnsType<DataType> = [
    {
      title: "订单号",
      key: "tradeNo",
      dataIndex: "tradeNo",
      align: "center",
      width: 260,
      // ellipsis: true,
      // render: (text, record) => (
      //   <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
      //     {text}
      //   </div>
      // ),
    },
    {
      title: "提交时间",
      dataIndex: "gmtCreate",
      key: "gmtCreate",
      align: "center",
    },
    {
      title: "申请人",
      dataIndex: "applicantName",
      key: "applicantName",
      align: "center",
    },
    // {
    //   title: "保函品种",
    //   dataIndex: "guaranteeCategoryName",
    //   key: "guaranteeCategoryName",
    //   align: "center",
    // },
    {
      title: "法院名称",
      dataIndex: "courtName",
      key: "courtName",
      align: "center",
    },
    {
      title: "保全类型",
      dataIndex: "preserveFormName",
      key: "preserveFormName",
      align: "center",
    },
    {
      title: "订单金额(元)",
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
    {
      title: "状态",
      dataIndex: "msg",
      key: "msg",
      align: "center",
      width: 92,
      fixed: "right",
    },
    {
      title: "操作",
      dataIndex: "",
      key: "oper",
      align: "center",
      width: 60,
      fixed: "right",
      render: (text, record: any) => {
        return (
          <span
            className="aui-link pl-[4]"
            onClick={() => {
              navigate!("/admin/procceedings/detail", {
                state: { tradeNo: record.tradeNo, id: record.id },
              });
            }}
          >
            查看
          </span>
        );
      },
    },
    //
  ];

  const [totalItems, setTotalItems] = useState<number>(0);
  const [statusAll, setStatusAll] = useState<any[]>([]); //状态选择框
  const [baoquanTypes, setquanTypes] = useState<any[]>([]); //保函类型选择框
  const [formItems, setFormItems] = useState<itmeType[]>([
    {
      label: "订单号",
      type: "input",
      key: "tradeNo",
      des: "请输入订单编号",
    },
    {
      label: "申请人",
      type: "input",
      key: "applicantName",
      des: "请输入申请人",
    },
    {
      label: "提交结束时间",
      type: "date",
      model: [],
      key: "gmtCreateBegin,gmtCreateEnd",
      des: ["开始时间", "结束时间"],
    },
    {
      label: "状态",
      type: "select",
      options: statusAll,
      key: "status",
      des: "请选择状态",
    },
    {
      label: "保全类型",
      type: "select",
      options: baoquanTypes,
      key: "preserveType",
      des: "请选择保全类型",
    },
    {
      label: "法院名称",
      type: "input",
      key: "courtName",
      des: "请输入法院名称",
    },
  ]);

  const [data, setData] = useState<any>([]);

  const [formData, setFormData] = useState<baoHan>({
    pageNo: 1, // 页数
    pageSize: 10, // 条数
    gmtCreateBegin: "", // 提交开始时间
    gmtCreateEnd: "", // 提交结束时间
    sortDirection: "", // 排序方向
    sortField: "", // 排序字段
    status: null, // 状态
    tradeNo: "", // 订单编号
    applicantName: "",
    preserveType: undefined,
    courtName: "",
  });

  const formRef = useRef<{
    getFormRef: () => FormInstance<any>;
    getValues: () => any;
  }>();

  const handelSeach = (values: any) => {
    setFormData({
      pageNo: 1,
      pageSize: 10,
      ...values,
    });
  };

  const handelPageChange = (pageNo: number, pageSize: number) => {
    setFormData({
      pageNo,
      pageSize,
      ...formRef.current?.getValues(),
    });
  };

  const getStatus = () => {
    ajax.post({
      url: "/openPlatform/common/allCourtActionOrderStatus",
      data: {},
      self: this,
      success: (res) => {
        res.forEach((el: any) => {
          statusAll.push({ ...el });
        });
        // setStatusAll((data: any) => {

        //   return data;
        // });
        // setFormItems(JSON.parse(JSON.stringify(formItems)));
      },
      fail: () => {},
    });
  };

  const getBaohanTypes = () => {
    ajax.post({
      url: "/openPlatform/common/allCourtPreserveForm",
      data: {},
      self: this,
      success: (res) => {
        res.forEach((el: any) => {
          baoquanTypes.push({ ...el });
        });
        // setquanTypes((data: any) => {
        //   res.map((el: any, index: Number) => {
        //     baoquanTypes.push({ ...el });
        //   });
        //   return data;
        // });
        // setFormItems(JSON.parse(JSON.stringify(formItems)));
      },
      fail: () => {},
    });
  };

  const getList = (data?: any) => {
    ajax.post({
      url: "/openPlatform/lawsuit/list",
      data: Object.assign(formData, data || {}),
      self: this,
      success: (res) => {
        setData(() => {
          return res.result.map((e: any, i: number) => {
            return { ...e, key: i };
          });
        });
        setTotalItems(res.totalItems);
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getStatus();
    getBaohanTypes();
  }, []);

  useEffect(() => {
    if (hasPermission("openPlatform:lawsuit:list")) {
      getList();
    }
  }, [formData]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {hasPermission("openPlatform:lawsuit:list") ? (
        <div>
          <div className="pb-[12]">
            <span className="t-[16] aui-192530 pr-[4]">
              法院诉讼保函订单
            </span>
          </div>
          <div className={`aui-bg-white ${proceedingsLess.wrap} `}>
            <div className={` ${proceedingsLess.bor_bttom}  p-[26]`}>
              <Dgform ref={formRef} items={formItems} handelSeach={handelSeach}>
                <Form.Item className="ml-[auto] mr-[0]">
                  <Button type="primary" htmlType="submit">
                    搜索
                  </Button>
                </Form.Item>
              </Dgform>
            </div>
            <div className="p-[26]">
              <Table
                columns={columns}
                pagination={false}
                dataSource={data}
                scroll={{ x: 1300 }}
              />
              <div className="text-center pt-[24]">
                <Pagination
                  onChange={handelPageChange}
                  total={totalItems}
                  showTotal={(total) => `总条数 ${total}`}
                ></Pagination>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
});
