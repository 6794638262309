import Header from "../../components/header";
import indexLess from "./css/forgotPasw.less";
import { Button, Row, Col, Form, Input, Alert } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
import DgCopyright from "../../components/dg-copyright";

type AlertType = "success" | "info" | "warning" | "error" | undefined;

let timer: string | number | NodeJS.Timeout | null | undefined = null;

export default () => {
  let [imgCode, setImgCode] = useState("");
  const [refForm] = Form.useForm();
  let [showAlert, setShowAlert] = useState(false);
  let [alert, setAlert] = useState("");
  let [alertType, setAlertType] = useState<AlertType>("error");
  let [countdown, setCountdown] = useState(false);
  let [count, setCount] = useState(60);
  const [phoneMobile, setPhoneMobile] = useState("");
  const [showPrompt, setShowPrompt] = useState(false);
  const navigate = useNavigate();
  /**
   * @author 伟健 / 张
   * @name 登录
   * @param
   * @return
   */
  let handlerSubmint = (values: any) => {
    ajax.post({
      url: "/openPlatform/auth/checkSmsCode",
      data: values,
      self: this,
      success: (res) => {
        if (res) {
          navigate("/forgetPwdLast");
          sessionStorage.setItem("modifyPaswLast_phone", values.mobile);
        }
      },
      fail: (err) => {
        setShowAlert(true);
        setAlertType("error");
        setAlert(err.msg);
        getGetcaptcha();
      },
    });
  };

  /**
   * @author 伟健 / 张
   * @name 图形码
   * @param
   * @return
   */
  let getGetcaptcha = () => {
    ajax.post({
      url: "/openPlatform/common/getcaptcha",
      data: {},
      self: this,
      success: (res) => {
        if (res) {
          setImgCode(res);
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author 伟健 / 张
   * @name 发送短信数据校验
   * @param
   * @return
   */
  let checkSmsCodeData = () => {
    let form = refForm.getFieldsValue(["captcha", "mobile"]);
    let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;

    if (!reg.test(form.mobile)) {
      // message.warning("请输入手机号");
      setShowAlert(true);
      setAlertType("error");
      setAlert("请输入手机号");
      return false;
    }
    if (!form.captcha) {
      setShowAlert(true);
      setAlertType("error");
      setAlert("请输入图形码");
      // message.warning("请输入图形码");
      return false;
    }
    return true;
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    setPhoneMobile(allValues.mobile);
  };

  const handleClose = () => {
    setShowAlert(false);
  };

  /**
   * @author 伟健 / 张
   * @name 发送短信
   * @param
   * @return
   */
  const handlerSendCode = () => {
    if (countdown) return;
    checkSmsCodeData();
    let form = refForm.getFieldsValue(["captcha", "mobile"]);
    ajax.post({
      url: "/openPlatform/common/sendSmsCode",
      data: { catchcode: form.captcha, mobile: form.mobile },
      self: this,
      success: (res) => {
        getGetcaptcha();
        setCountdown(true);
        setShowAlert(true);
        setAlert("发送成功");
        setAlertType("success");
        setShowPrompt(true);
        timer = setInterval(() => {
          if (count <= 0) {
            setCountdown(false);
            setCount(60);
            timer && clearInterval(timer);
            timer = null;
            return;
          }
          count = count - 1;
          setCount(count);
        }, 1000);
      },
      fail: (err) => {
        setShowAlert(true);
        setAlertType("error");
        setAlert(err.msg);
        getGetcaptcha();
      },
    });
  };

  useEffect(() => {
    getGetcaptcha();
  }, []);
  return (
    <div className="w-[100%] h-[100vh] min-h-[750] bg-[#FFFFFF] min-w-[1200] relative">
      <Header></Header>
      <div className={`min-w-[1200] ${indexLess.forgetForm}`}>
        <div className="mx-[auto] w-[400] pt-[48] rounded-[4]">
          <div className="text-center t-[24] t-[#192530] leading-[28] tracking-[4] mb-[24] ">
            忘记密码
          </div>
          {showAlert && (
            <div className="pb-[16]">
              <Alert
                showIcon
                closable
                message={alert}
                type={alertType}
                afterClose={handleClose}
                icon={<i className="icon t-[16]">&#xe664;</i>}
                className="!px-[16] !py-[8] !border-[0]"
              />
            </div>
          )}
          <Form
            size="large"
            form={refForm}
            onFinish={handlerSubmint}
            onValuesChange={onValuesChange}
          >
            <Form.Item
              label=""
              name="mobile"
              rules={[
                { required: true, message: "请输入手机号" },
                {
                  pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
                  message: "请输入手机号",
                },
              ]}
            >
              <Input
                maxLength={11}
                placeholder="* 请输入手机号"
                autoComplete="off"
              />
            </Form.Item>

            <Form.Item label="" rules={[{ required: true, message: "" }]}>
              <Row gutter={8}>
                <Col span={17}>
                  <Form.Item
                    name="captcha"
                    noStyle
                    rules={[{ required: true, message: "请输入图片验证码" }]}
                  >
                    <Input placeholder="图片验证码" />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <img
                    src={imgCode}
                    className={indexLess.imgCode}
                    onClick={() => getGetcaptcha()}
                    alt=""
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Form.Item
                noStyle={true}
                name="catchcode"
                rules={[{ required: true, message: "请输入短信验证码" }]}
              >
                <Input
                  placeholder="短信验证码"
                  suffix={
                    <span
                      className={indexLess.suffix}
                      onClick={handlerSendCode}
                    >
                      {countdown ? `${count}/s` : "发送短信"}
                    </span>
                  }
                />
              </Form.Item>
              {showPrompt && (
                <div className="absolute bg-[#F0F2F5] w-[246] h-[22] rounded-[4] leading-[22] top-[50] right-[0] t-[12] text-center">
                  <span className="t-[#303133]" style={{ zoom: "0.9" }}>
                    验证码已发送给{phoneMobile}，10分钟内有效
                  </span>
                </div>
              )}
            </Form.Item>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                className={indexLess.login_button}
              >
                下一步
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <DgCopyright
        style={{ position: "absolute", bottom: "30px" }}
        paddingTop="0px"
        paddingBottom="0px"
      />
    </div>
  );
};
