import EmptyPage from "../../components/emptyPage";
import Dglist from "../../components/dg-list";
import Dglistitem from "../../components/dg-list-item";
import { Button, Image } from "antd";

import indexLess from "./css/index.less";
import { useState, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
import { hasPermission } from "../../assets/js/hasPermission";

export default memo(() => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    enterpriseName: "",
    socialCreditCode: "",
    statusName: "",
    typeName: "",
    enterpriseAddress: "",
    legalPersonName: "",
    legalPersonMobile: "",
    businessLicense: "",
    auditRemark: "",
    status: -1,
  });

  /**
   * @author 伟健 / 张
   * @name 账号信息
   * @param
   * @return
   */
  let getAccountInfo = () => {
    ajax.post({
      url: "/openPlatform/user/current?t=company",
      data: {},
      self: this,
      success: (res) => {
        setData(res.financialInstitution || {});
      },
      fail: () => {},
    });
  };

  /**
   * @author 伟健 / 张
   * @name 企业状态
   * @param
   * @return
   */
  let getEnterpriseStatus = () => {
    ajax.post({
      url: "/openPlatform/common/allEnterpriseStatus",
      data: {},
      self: this,
      success: (res) => {
        console.log(res);
      },
      fail: () => {},
    });
  };
  /* 
	[
    {
        "key": 0,
        "value": "等待完善"
    },
    {
        "key": 1,
        "value": "等待审核"
    },
    {
        "key": 2,
        "value": "审核通过"
    },
    {
        "key": 3,
        "value": "审核未通过"
    }
]
	*/

  useEffect(() => {
    // if (hasPermission("openPlatform:user")) {
    getAccountInfo();
    getEnterpriseStatus();
    // }
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="">
        <div className="pb-[12] flex justify-between">
          <div>
            <span className="t-[16] aui-192530 pr-[4]">
              企业认证
            </span>
          </div>

          {(data.status == 0 || data.status == 3) && (
            <div>
              <Button
                onClick={() => navigate("/admin/companyEdit")}
                type="primary"
              >
                去认证
              </Button>
            </div>
          )}
        </div>
        <div className={`aui-bg-white ${indexLess.wrap}`}>
          <div
            className={`pl-[26] pr-[26] pb-[20] pt-[20] ${indexLess.bor_bttom}`}
          >
            <div>
              <span
                className={`t-[18] aui-192530 ${indexLess.title}`}
              >
                状态
              </span>
            </div>
            <Dglist column={2} labelWidth="140px">
              <Dglistitem label="企业状态">{data.statusName}</Dglistitem>
              <Dglistitem label="审核备注">{data.auditRemark}</Dglistitem>
            </Dglist>
          </div>
          <div className=" pl-[26] pr-[26] pb-[20] pt-[20]">
            <div className="pb-[16]">
              <span
                className={`t-[18] aui-192530 ${indexLess.title}`}
              >
                企业信息
              </span>
            </div>
            <Dglist column={2} labelWidth="140px">
              <Dglistitem label="企业名称">{data.enterpriseName}</Dglistitem>
              <Dglistitem label="统一信用代码">
                {data.socialCreditCode}
              </Dglistitem>
              <Dglistitem label="企业类型">{data.typeName}</Dglistitem>
              <Dglistitem label="企业地址">{data.enterpriseAddress}</Dglistitem>
              <Dglistitem label="法人姓名">{data.legalPersonName}</Dglistitem>
              <Dglistitem label="法人手机号">
                {data.legalPersonMobile}
              </Dglistitem>
              <Dglistitem label="营业执照">
                <Image src={data.businessLicense}></Image>
              </Dglistitem>
            </Dglist>
          </div>
        </div>
      </div>
      {/* {hasPermission("openPlatform:user") ? (
        
      ) : (
        <EmptyPage />
      )} */}
    </div>
  );
});
