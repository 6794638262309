import indexLess from "./css/productDetail.less";
import { Button, Modal, message } from "antd";
import { useState, useEffect } from "react";
import ajax from "../../assets/js/ajax";
import { useLocation } from "react-router-dom";
// import ofdImg from "@/assets/img/ofd.png";
// import queryImg from "@/assets/img/query.png";
// const { Option } = Select;
export default () => {
  // const navigate = useNavigate();

  let [invoiceVisible, setInvoiceVisible] = useState(false);
  const [detail, setDetail] = useState({
    describe: "",
    detail: "",
    productName: "",
    logo: "",
    productCode: "",
    status: false,
  });
  const params = useLocation().state as {
    id: string;
  };
  /**
   * @author 伟健 / 张
   * @name 产品详情
   * @param
   * @return
   */
  let getDetail = () => {
    if (!params.id) {
      return;
    }
    ajax.post({
      url: "/openPlatform/product/detail",
      data: { id: params.id },
      self: this,
      success: (res) => {
        setDetail(res);
      },
      fail: () => {},
    });
  };
  /**
   * @author 伟健 / 张
   * @name 开通产品
   * @param
   * @return
   */
  let handlerOpen = () => {
    if (!params.id) {
      return;
    }
    ajax.post({
      url: "/openPlatform/product/personalOpen",
      data: { productId: params.id },
      self: this,
      success: (res) => {
        console.log(res);
        if (res) {
          setInvoiceVisible(false);
          getDetail();
          message.success("开通成功");
        }
      },
      fail: () => {},
    });
  };
  useEffect(() => {
    getDetail();
  }, []);
  return (
    <div className="">
      <div className="pb-[12]">
        <span className="t-[16] aui-192530 pr-[4]">
          产品详情
        </span>
      </div>
      <div className={`aui-bg-white ${indexLess.wrap} `}>
        <div className={`${indexLess.bor_bttom}`}>
          <div className={` flex items-center p-[20]`}>
            <div className={`${indexLess.icon_box} flex`}>
              <img src={detail.logo} alt="" />
            </div>
            <div className="pl-[20]">
              <p className="t-[20] aui-333333">
                {detail.productName}
              </p>
              <p className="t-[14] pt-[10] aui-666666">
                Code：{detail.productCode}
              </p>
            </div>
            <div className="ml-[auto] mr-[0]">
              {detail.status ? (
                <Button type="primary">已开通</Button>
              ) : (
                <Button type="primary" onClick={() => setInvoiceVisible(true)}>
                  申请开通
                </Button>
              )}
            </div>
          </div>
          <p className="aui-666666 t-[14] pl-[20] pb-[12]">
            描述：{detail.describe}
          </p>
        </div>
        <div className="p-[20]">
          <p>
            <span className={`t-[18] aui-192530 ${indexLess.title}`}>
              说明
            </span>
          </p>
          <div
            className="p-[12]"
            dangerouslySetInnerHTML={{
              __html: detail.detail,
            }}
          ></div>
        </div>
      </div>
      {/* open={isModalOpen} onOk={handleOk} onCancel={handleCancel} */}
      {/* <Modal title="申请发票" > */}
      <Modal
        forceRender={true}
        maskClosable={true}
        onCancel={() => setInvoiceVisible(false)}
        open={invoiceVisible}
        footer={false}
        centered
        width={400}
      >
        <div className="text-center ">
          <p className="t-[20] aui-color-main pt-[16]">
            开通产品
          </p>
          <p className="pt-[8] t-[14] aui-999CA1">
            确定开通此产品？
          </p>
          <div className="pt-[30]">
            <Button
              className="w-200"
              type="primary"
              onClick={() => handlerOpen()}
            >
              确定
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
