import proceedingsLess from "./css/order.less";
import Dgform from "../../components/dg-form";
import { Table, Button, Form, Pagination, Modal, message, Input } from "antd";
import type { FormInstance } from "antd";
import { memo, useEffect, useRef, useState } from "react";
import { itmeType } from "../../types";
import ajax from "../../assets/js/ajax";
import type { ColumnsType } from "antd/es/table";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import EmptyPage from "../../components/emptyPage";
import { hasPermission } from "../../assets/js/hasPermission";
// import EmptyPage from "../../components/emptyPage";
// import { hasPermission } from "../../assets/js/hasPermission";

let navigate: NavigateFunction | null = null;

interface DataType {
  insureEffectTimeStart: string;
  insureEffectTimeEnd: string;
  key: React.Key;
  title: string;
  dataIndex: string;
  align: string;
}

interface baoHan {
  pageNo: number | null; // 页数
  pageSize: number | null; // 条数
  sortDirection?: string; // 排序方向
  sortField?: string; // 排序字段
  status?: number | null; // 状态
  safeLiabilityInsureOrderNo?: string; // 安责险保函编号
  projectName?: string; // 项目名称
  name?: string; // 服务名称
  tradeNo?: string; // 服务订单号
  safetyEnterpriseName?: string; // 安评公司名称
}

export default memo(() => {
  navigate = useNavigate();

  const columns: ColumnsType<DataType> = [
    {
      title: "项目名称",
      dataIndex: "projectName",
      key: "projectName",
      align: "center",
    },
    {
      title: "施工单位名称",
      key: "constructionUnitName",
      dataIndex: "constructionUnitName",
      align: "center",
    },
    {
      title: "安责险保函编号",
      dataIndex: "insureOrderNo",
      key: "insureOrderNo",
      align: "center",
    },
    {
      title: "保函期限",
      dataIndex: "term",
      key: "term",
      align: "center",
      render: (_, record) => {
        return (
          <div>
            {record.insureEffectTimeStart}~{record.insureEffectTimeEnd}
          </div>
        );
      },
    },
    {
      title: "服务名称",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "服务订单编号",
      dataIndex: "tradeNo",
      key: "tradeNo",
      align: "center",
    },
    {
      title: "安评公司名称",
      dataIndex: "safetyEnterpriseName",
      key: "safetyEnterpriseName",
      align: "center",
    },
    {
      title: "服务状态",
      dataIndex: "statusDesc",
      key: "statusDesc",
      align: "center",
      width: 110,
      fixed: "right",
    },
    {
      title: "操作",
      dataIndex: "",
      key: "oper",
      align: "center",
      width: 170,
      fixed: "right",
      render: (text, record: any) => {
        return (
          <div className="flex justify-center">
            <span
              className="aui-link pl-[4]"
              onClick={() => {
                navigate!("/admin/accident/detail", {
                  state: { tradeNo: record.tradeNo },
                });
              }}
            >
              查看
            </span>
            {[0, 7, 8].includes(+record.status) && (
              <div>
                <span
                  className="aui-link pl-[4]"
                  onClick={() => {
                    navigate!("/admin/accidentService", {
                      state: { tradeNo: record.tradeNo },
                    });
                  }}
                >
                  服务项目
                </span>
                <span
                  className="aui-link pl-[4]"
                  onClick={() => {
                    navigate!("/admin/accident/complaint", {
                      state: { tradeNo: record.tradeNo },
                    });
                  }}
                >
                  投诉
                </span>
              </div>
            )}
            {[1, 2, 4].includes(+record.status) && (
              <span
                className="aui-link pl-[4]"
                onClick={() => {
                  setCloseTradeNo(record.tradeNo);
                  setClose(true);
                }}
              >
                关闭订单
              </span>
            )}

            {/* 无法受理 */}
            {record.status == 2 && (
              <span
                className="aui-link pl-[4]"
                onClick={() => {
                  navigate!("/admin/accidentConfigure", {
                    state: {
                      type: "edit",
                      tradeNo: record.tradeNo,
                      safeLiabilityTradeNo: record.safeLiabilityTradeNo,
                      insureEffectTimeStart: record.startTime,
                      insureEffectTimeEnd: record.insureEffectTimeEnd,
                    },
                  });
                }}
              >
                继续申请
              </span>
            )}

            {/* 等待支付 */}
            {record.status == 4 && (
              <span
                className="aui-link pl-[4]"
                onClick={() => {
                  navigate!("/admin/accident/pay", {
                    state: { tradeNo: record.tradeNo },
                  });
                }}
              >
                继续申请
              </span>
            )}
          </div>
        );
      },
    },
    //
  ];

  const [totalItems, setTotalItems] = useState<number>(0);
  const [statusAll, setStatusAll] = useState<any[]>([]); //状态选择框
  // const [baoquanTypes, setquanTypes] = useState<any[]>([]); //保函类型选择框
  const [formItems, setFormItems] = useState<itmeType[]>([
    {
      label: "项目名称",
      type: "input",
      key: "projectName",
      des: "请输入项目名称",
    },
    {
      label: "安责险保函编号",
      type: "input",
      key: "safeLiabilityInsureOrderNo",
      des: "请输入安责险保函编号",
    },
    {
      label: "服务名称",
      type: "input",
      key: "name",
      des: "请输入服务名称",
    },
    {
      label: "服务订单编号",
      type: "input",
      key: "tradeNo",
      des: "请输入服务订单编号",
    },
    {
      label: "安评公司名称",
      type: "input",
      key: "safetyEnterpriseName",
      des: "请输入安评公司名称",
    },
    {
      label: "服务状态",
      type: "select",
      options: statusAll,
      key: "status",
      des: "请选择服务状态",
    },
  ]);

  const [data, setData] = useState<any>([]);

  const [refForm] = Form.useForm();
  const [close, setClose] = useState<boolean>(false);
  const [closeTradeNo, setCloseTradeNo] = useState<string>("");

  const [formData, setFormData] = useState<baoHan>({
    pageNo: 1, // 页数
    pageSize: 10, // 条数
    sortDirection: "", // 排序方向
    sortField: "", // 排序字段
    status: null, // 状态
    tradeNo: "", // 订单编号
    projectName: "",
    name: "", //服务名称
    safetyEnterpriseName: "",
    safeLiabilityInsureOrderNo: "",
  });

  const formRef = useRef<{
    getFormRef: () => FormInstance<any>;
    getValues: () => any;
  }>();

  const handelSeach = (values: any) => {
    setFormData({
      pageNo: 1,
      pageSize: 10,
      ...values,
    });
  };

  const handelPageChange = (pageNo: number, pageSize: number) => {
    setFormData({
      pageNo,
      pageSize,
      ...formRef.current?.getValues(),
    });
  };

  // 提交关闭订单
  const handlerSubmint = (values: any) => {
    ajax.post({
      url: "/openPlatform/accidentPreventionOrder/close",
      data: { ...values, tradeNo: closeTradeNo },
      self: this,
      success: (res) => {
        if (res) {
          setClose(false);
          getList();
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  const getStatus = () => {
    ajax.post({
      url: "/openPlatform/common/allAccidentPreventionOrderStatus",
      data: {},
      self: this,
      success: (res) => {
        res.forEach((el: any) => {
          statusAll.push({ ...el });
        });
      },
      fail: () => {},
    });
  };

  const getList = (data?: any) => {
    ajax.post({
      url: "/openPlatform/accidentPreventionOrder/page",
      data: Object.assign(formData, data || {}),
      self: this,
      success: (res) => {
        setData(() => {
          return res.result.map((e: any, i: number) => {
            return { ...e, key: i };
          });
        });
        setTotalItems(res.totalItems);
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    if (hasPermission("openPlatform:accidentPreventionOrder:page")) {
    getList();
    }
  }, [formData]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {hasPermission("openPlatform:accidentPreventionOrder:page") ? (
      <div>
        <div className="pb-[12]">
          <span className="t-[16] aui-192530 pr-[4]">
            事故预防服务订单
          </span>
        </div>
        <div className={`aui-bg-white ${proceedingsLess.wrap} `}>
          <div className={` ${proceedingsLess.bor_bttom}  p-[26]`}>
            <Dgform ref={formRef} items={formItems} handelSeach={handelSeach}>
              <Form.Item className="ml-[auto] mr-[0]">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="mr-[10]"
                >
                  搜索
                </Button>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    navigate!("/admin/accident/project", {
                      state: { tradeNo: "" },
                    });
                  }}
                >
                  添加服务
                </Button>
              </Form.Item>
            </Dgform>
          </div>
          <div className="p-[26]">
            <Table
              columns={columns}
              pagination={false}
              dataSource={data}
              scroll={{ x: 1300 }}
            />
            <div className="text-center pt-[24]">
              <Pagination
                onChange={handelPageChange}
                total={totalItems}
                showTotal={(total) => `总条数 ${total}`}
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
      ) : (
        <EmptyPage />
      )} 

      <Modal
        title="关闭订单"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setClose(false)}
        open={close}
        footer={false}
        centered
        width={500}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={refForm}
          onFinish={handlerSubmint}
        >
          <Form.Item
            label="取消原因"
            name="reason"
            rules={[{ required: true, message: "请输入取消原因" }]}
          >
            <Input.TextArea rows={4} placeholder="请输入取消原因" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setClose(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});
