import { Table, Pagination } from "antd";
import { memo, useEffect, useState } from "react";
import ajax from "../../../assets/js/ajax";
import type { ColumnsType } from "antd/es/table";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import emptyPng from "../../../assets/img/empty.png";

let navigate: NavigateFunction | null = null;

interface DataType {
  key: React.Key;
  title: string;
  dataIndex: string;
  align: string;
}
interface PropType {
  realEnterpriseId: string | number;
}

export default (props: PropType) => {
  navigate = useNavigate();
  const { realEnterpriseId } = props;
  const params = useLocation().state as {
    tradeNo: string;
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "付款编号",
      key: "paymentNo",
      dataIndex: "paymentNo",
      align: "center",
    },
    {
      title: "融资方基本户开户行及支行",
      dataIndex: "bankDot",
      key: "bankDot",
      align: "center",
    },
    {
      title: "融资方基本户卡号",
      dataIndex: "bankCardNo",
      key: "bankCardNo",
      align: "center",
    },
    {
      title: "收款金额(元)",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      align: "center",
    },
    {
      title: "收款时间",
      dataIndex: "paymentDate",
      key: "paymentDate",
      align: "center",
    },
    {
      title: "付款凭证号",
      dataIndex: "paymentVoucherNo",
      key: "paymentVoucherNo",
      align: "center",
    },
    {
      title: "核心企业名称",
      dataIndex: "coreEnterpriseName",
      key: "coreEnterpriseName",
      align: "center",
    },
    {
      title: "核心企业统一社会信用代码",
      dataIndex: "coreCreditCode",
      key: "coreCreditCode",
      align: "center",
    },
  ];

  const [totalItems, setTotalItems] = useState<number>(0);

  const [data, setData] = useState<any>([]);

  const [isEmpty, setIsEmpty] = useState(true);

  const [formData, setFormData] = useState<any>({
    pageNo: 1, // 页数
    pageSize: 10, // 条数
  });

  const handelPageChange = (pageNo: number, pageSize: number) => {
    setFormData({
      pageNo,
      pageSize,
    });
  };

  const getList = (data?: any) => {
    ajax.post({
      url: "/openPlatform/accountsReceivableFinancing/chainPayPage",
      data: { ...params, realEnterpriseId, ...formData },
      self: this,
      success: (res) => {
        if (res.result) {
          setData(() => {
            return res.result.map((e: any, i: number) => {
              return { ...e, key: i };
            });
          });
        }
        setTotalItems(res.totalItems);
        setIsEmpty(res.totalItems <= 0 ? true : false);
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getList();
  }, [formData]);

  return (
    <div className="w-[100%] h-[100%]">
      <div className="t-[14] t-[#BEBEBE] pt-[10] pb-[20] ">
        注：仅支持在融资期限内查询，融资期限过后，将关闭入口。可查询融资期间新增的应收账款收款记录。
      </div>
      {isEmpty ? (
        <div className="text-center my-[130] mx-[auto]">
          <img src={emptyPng} alt="" />
          <p className="mt-[28] t-[16] t-[#909399]">
            融资期限已结束，不支持查询该企业账款信息
          </p>
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            pagination={false}
            dataSource={data}
            scroll={{ x: 1100 }}
          />
          <div className="text-center pt-[24]">
            <Pagination
              onChange={handelPageChange}
              total={totalItems}
              showTotal={(total) => `总条数 ${total}`}
            ></Pagination>
          </div>
        </div>
      )}
    </div>
  );
};
