import Dgupload from "../../components/dg-upload";
import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  message,
} from "antd";
import proceedingDetailLess from "./css/detail.less";
import { memo, useEffect, useState } from "react";
import Dglist from "../../components/dg-list";
import Dglistitem from "../../components/dg-list-item";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
import { downloadFile } from "../../assets/js/util";
// import { ColumnsType } from "antd/es/table";

let navigate: NavigateFunction | null = null;

export default memo(() => {
  navigate = useNavigate();
  const params = useLocation().state as {
    tradeNo: string;
    id: string;
  };

  const [data, setData] = useState<any>({});
  const [respondentBeau, setRespondentBeau] = useState<any>({});
  const [id, setId] = useState<number | null>();
  const [version, setVersion] = useState<number | null>();

  // 单选框事件
  const onRadioChange = (e: RadioChangeEvent) => {
    setRespondentBeau(e.target.value);
  };

  // 投诉内容
  const [complaintForm] = Form.useForm();
  const [complainantAttachment, setComplainantAttachment] =
    useState<string>("");
  //提交投诉内容
  const handlerSubmitComplaint = (values: any) => {
    const _respondentBeau = data.type == 1 ? 1 : respondentBeau;
    ajax.post({
      url: "/openPlatform/serviceComplaint/createOrEdit",
      data: {
        ...values,
        tradeNo: params.tradeNo,
        respondentBeau: _respondentBeau,
        complainantAttachment,
        id,
        version,
      },
      self: this,
      success: (res) => {
        message.success("已提交给对应监管部门，请耐心等候处理");
        // navigate!("/admin/accidentOrder");
        navigate!(-1);
      },
      fail: () => {},
    });
  };

  // 获取投诉详情
  const getComplaint = () => {
    ajax.post({
      url: "/openPlatform/serviceComplaint/detail",
      data: { id: params.id },
      self: this,
      success: (res) => {
        setId(res.id);
        setVersion(res.version);
        setRespondentBeau(res.respondentBeau);
        setComplainantAttachment(res.complainantAttachment);
        complaintForm.setFieldsValue({
          respondentBeau: res.respondentBeau,
          complainantRemark: res.complainantRemark,
          complainantAttachment: res.complainantAttachment,
        });
      },
      fail: () => {},
    });
  };

  const getDetail = () => {
    ajax.post({
      url: "/openPlatform/accidentPreventionOrder/detail",
      data: params,
      self: this,
      success: (res) => {
        setData(res || {});
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getDetail();
    if (params.id) {
      getComplaint();
    }
  }, []);

  return (
    <div>
      <div className="pb-[12]">
        <span className="t-[16] aui-192530 pr-[4]">
          提交投诉
        </span>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>基础信息</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="项目名称">{data.projectName}</Dglistitem>
          <Dglistitem label="施工企业名称">
            {data.constructionUnitName}
          </Dglistitem>
          <Dglistitem label="服务名称">{data.name}</Dglistitem>
          <Dglistitem label="服务开始、结束时间">
            {`${data.startTime}~${data.insureEffectTimeEnd}`}
          </Dglistitem>
          <Dglistitem label="服务方案">
            <div>
              {data.plan ? (
                <span>
                  <a href={data.plan} target="_blank">
                    查看
                  </a>

                  <span
                    className="aui-link pl-[6]"
                    onClick={() => downloadFile(data.plan, "服务方案")}
                  >
                    下载
                  </span>
                </span>
              ) : (
                "-"
              )}
            </div>
          </Dglistitem>
          <Dglistitem label="服务合同">
            <div>
              {data.contract ? (
                <span>
                  <a href={data.contract} target="_blank">
                    查看
                  </a>

                  <span
                    className="aui-link pl-[6]"
                    onClick={() => downloadFile(data.contract, "服务合同")}
                  >
                    下载
                  </span>
                </span>
              ) : (
                "-"
              )}
            </div>
          </Dglistitem>
          <Dglistitem label="安责险保函编号">{data.insureOrderNo}</Dglistitem>
          <Dglistitem label="保函期限">
            {data.insureEffectTimeStart && data.insureEffectTimeEnd
              ? `${data.insureEffectTimeStart}~${data.insureEffectTimeEnd}`
              : ""}
          </Dglistitem>
        </Dglist>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>投诉内容</p>
        {data.type == 0 && (
          <Form
            preserve={false}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            form={complaintForm}
            onFinish={handlerSubmitComplaint}
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  label="投诉对象"
                  name="respondentBeau"
                  rules={[{ required: true, message: "请选择状态" }]}
                >
                  <Radio.Group onChange={onRadioChange}>
                    <Radio value={1}> 施工企业 </Radio>
                    <Radio value={2}> 安评公司 </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {respondentBeau == 1 && (
                <Col span={12}>
                  <Form.Item label="企业名称">
                    <Input
                      value={data.constructionUnitName}
                      placeholder="请输入企业名称"
                      disabled
                    />
                  </Form.Item>
                </Col>
              )}
              {respondentBeau == 2 && (
                <Col span={12}>
                  <Form.Item label="安评公司名称">
                    <Input
                      value={data.safetyEnterpriseName}
                      placeholder="请输入企业名称"
                      disabled
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="投诉内容"
                  name="complainantRemark"
                  rules={[{ required: true, message: "请输入投诉内容" }]}
                >
                  <Input.TextArea rows={4} placeholder="请输入投诉内容" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="投诉材料"
                  hasFeedback
                  name="complainantAttachment"
                  rules={[
                    {
                      required: true,
                      message: "请上传投诉材料",
                    },
                  ]}
                >
                  <div>
                    <div className={proceedingDetailLess.suffixBox}>
                      请上传投诉材料，支持.rar .zip .doc .docx .pdf
                      .jpg格式，大小不超过50M
                    </div>
                    <Dgupload
                      fileName="投诉材料"
                      button={true}
                      type={[".pdf", ".rar", ".doc", ".docx", ".jpg"]}
                      value={complainantAttachment}
                      setValue={setComplainantAttachment}
                    ></Dgupload>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item wrapperCol={{ offset: 6 }}>
              <Button
                type="default"
                className="w-[150]"
                onClick={() => {
                  navigate!(-1);
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                className="w-[][150] ml-[20]"
                htmlType="submit"
              >
                提交
              </Button>
            </Form.Item>
          </Form>
        )}
        {data.type == 1 && (
          <Form
            preserve={false}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            form={complaintForm}
            onFinish={handlerSubmitComplaint}
          >
            <Row>
              <Col span={12}>
                <Form.Item label="投诉对象" name="respondentBeau">
                  <Input defaultValue="施工企业" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="企业名称">
                  <Input
                    value={data.constructionUnitName}
                    placeholder="请输入企业名称"
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="投诉内容"
                  name="complainantRemark"
                  rules={[{ required: true, message: "请输入投诉内容" }]}
                >
                  <Input.TextArea rows={4} placeholder="请输入投诉内容" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="投诉材料" name="complainantAttachment">
                  <div>
                    <div className={proceedingDetailLess.suffixBox}>
                      请上传投诉材料，支持.rar .zip .doc .docx .pdf
                      .jpg格式，大小不超过50M
                    </div>
                    <Dgupload
                      fileName="投诉材料"
                      button={true}
                      type={[".pdf", ".rar", ".doc", ".docx", ".jpg"]}
                      value={complainantAttachment}
                      setValue={setComplainantAttachment}
                    ></Dgupload>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item wrapperCol={{ offset: 5 }}>
                  <Button
                    type="default"
                    className="w-[150]"
                    onClick={() => {
                      navigate!(-1);
                    }}
                  >
                    取消
                  </Button>
                  <Button
                    type="primary"
                    className="w-150 ml-[20]"
                    htmlType="submit"
                  >
                    提交
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </div>
  );
});
