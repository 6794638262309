import DgCard from "../../components/dg-card";
// import detailLess from "./components/css/detail.less";
import Dglist from "../../components/dg-list";
import Dglistitem from "../../components/dg-list-item";
import idCardFront from "../../assets/img/idCardFront.png";
import idCardBack from "../../assets/img/idCardBack.png";
import { Button, Image } from "antd";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ajax from "../../assets/js/ajax";

let navigate: NavigateFunction | null = null;

export default () => {
  navigate = useNavigate();
  const params = useLocation().state as {
    id: string;
  };

  const [data, setData] = useState<any>({});

  const getDetail = () => {
    ajax.post({
      url: "/openPlatform/supply/chainSignContract/detail",
      data: params,
      self: this,
      success: (res) => {
        setData(res ?? {});
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <div>
      <div className="pb-[12]">
        <span className="t-[16] t-[#192530] pr-[4]">核心企业详情</span>
      </div>
      <DgCard title="企业信息">
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="核心企业名称">
            {data.enterpriseName || "-"}
          </Dglistitem>
          <Dglistitem label="统一社会信用代码">
            {data.enterpriseCode || "-"}
          </Dglistitem>
          <Dglistitem label="企业地址">{data.address || "-"}</Dglistitem>
          <Dglistitem label="企业电话">{data.enterpriseMobile || "-"}</Dglistitem>
          <div className="w-[100%]">
            <Dglistitem labelWidth="200px" label="营业执照">
              <Image width={126} height={95} src={data.businessLicense || ""} />
            </Dglistitem>
          </div>
          <Dglistitem label="法人姓名">{data.legalPersonName || "-"}</Dglistitem>
          <Dglistitem label="法人手机号">{data.legalPersonMobile || "-"}</Dglistitem>
          <Dglistitem label="法人身份证号">
            {data.legalPersonIdCardNo || "-"}
          </Dglistitem>
          <Dglistitem label="法人身份证照">
            <div className="flex">
              <Image
                width={126}
                height={95}
                src={data.legalPersonIdCardFront || ""}
                fallback={idCardFront}
              />
              <Image
                width={126}
                height={95}
                src={data.legalPersonIdCardReverse || ""}
                fallback={idCardBack}
                className="ml-[10]"
              />
            </div>
          </Dglistitem>
          <div className="w-[100%]">
            <Dglistitem labelWidth="200px" label="企业简介">{data.introduce || "-"}</Dglistitem>
          </div>
          <Dglistitem label="签约时间">{data.signingTime || "-"}</Dglistitem>
        </Dglist>
      </DgCard>
      <div className="text-center mt-[32]">
        <Button
          type="default"
          className="!w-[160]"
          onClick={() => {
            navigate!("/admin/supplyCores");
          }}
        >
          返回
        </Button>
      </div>
    </div>
  );
};
