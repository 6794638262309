import Header from "./components/header";
import registerLess from "./register.less";
import { Button, Checkbox, Row, Col, Form, Input, message, Alert } from "antd";
import { useState, useEffect } from "react";
import { encryptDES } from "./assets/js/util";
import ajax from "./assets/js/ajax";
import { useNavigate } from "react-router-dom";
import DgPaswIntensity from "./components/dg-pasw-intensity";
import DgCopyright from "./components/dg-copyright";

type AlertType = "success" | "info" | "warning" | "error" | undefined;

let timer: string | number | NodeJS.Timeout | null | undefined = null;

export default () => {
  const [useForm] = Form.useForm();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState("");
  const [password, setPassword] = useState("");
  const [alertType, setAlertType] = useState<AlertType>("error");
  const [imgCode, setImgCode] = useState("");
  const [phoneMobile, setPhoneMobile] = useState("");
  const [countdown, setCountdown] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  let [count, setCount] = useState(60);

  const handleClose = () => {
    setShowAlert(false);
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    setPassword(allValues.password);
    setPhoneMobile(allValues.mobile);
  };

  /**
   * @author 伟健 / 张
   * @name 图形码
   * @param
   * @return
   */
  let getGetcaptcha = () => {
    ajax.post({
      url: "/openPlatform/common/getcaptcha",
      data: {},
      self: this,
      success: (res) => {
        if (res) {
          setImgCode(res);
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author 伟健 / 张
   * @name 提交注册
   * @param
   * @return
   */
  let handlerSubmint = (values: any) => {
    if (!values.isProtocol) {
      setShowAlert(true);
      setAlertType("error");
      setAlert("请同意协议");
      return;
    }
    values.password = encryptDES(values.password);
    ajax.post({
      url: "/openPlatform/auth/regist",
      data: values,
      self: this,
      success: (res) => {
        setShowAlert(true);
        setAlertType("success");
        setAlert("注册成功");
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      },
      fail: (err) => {
        setShowAlert(true);
        setAlertType("error");
        setAlert(err.msg);
        getGetcaptcha();
      },
    });
  };

  /**
   * @author 伟健 / 张
   * @name 发送短信数据校验
   * @param
   * @return
   */
  let checkSmsCodeData = () => {
    let form = useForm.getFieldsValue(["catchcode", "mobile"]);
    let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;

    if (!reg.test(form.mobile)) {
      setShowAlert(true);
      setAlert("请输入手机号");
      setAlertType("error");
      return false;
    }
    if (!form.catchcode) {
      setShowAlert(true);
      setAlert("请输入图形码");
      setAlertType("error");
      return false;
    }
    return true;
  };

  const handlerSendCode = () => {
    if (countdown) return;
    checkSmsCodeData();
    let form = useForm.getFieldsValue(["catchcode", "mobile"]);
    ajax.post({
      url: "/openPlatform/common/sendSmsCode",
      data: { catchcode: form.catchcode, mobile: form.mobile },
      self: this,
      success: (res) => {
        getGetcaptcha();
        setCountdown(true);
        setShowAlert(true);
        setAlert("发送成功");
        setAlertType("success");
        setShowPrompt(true);
        timer = setInterval(() => {
          if (count <= 0) {
            setCountdown(false);
            setCount(60);
            timer && clearInterval(timer);
            timer = null;
            return;
          }
          count = count - 1;
          setCount(count);
        }, 1000);
      },
      fail: (err) => {
        setShowAlert(true);
        setAlertType("error");
        setAlert(err.msg);
        getGetcaptcha();
      },
    });
  };

  const passwordVail = (rule: any, value: string, callback: any) => {
    if (value === "") {
      callback(new Error("密码格式错误，请重新输入"));
    } else if (
      !/^(?!.*(.)\1{2,})(?=.*[a-zA-Z].*[0-9]|[a-zA-Z].*[!@#$%^&*()_+[\]{};':"\\|,.<>?`~]|[0-9].*[!@#$%^&*()_+[\]{};':"\\|,.<>?`~]).{6,32}$/.test(
        value
      )
    ) {
      callback(new Error("密码格式错误，请重新输入"));
    } else {
      callback();
    }
  };

  const enterpriseNameVail = (rule: any, value: any, callback: any) => {
    if (value === "") {
      callback(new Error("请填写注册的企业名称"));
    } else if (value.replace(/[^\u0000-\u00ff]/g, "aa").length < 8) {
      callback(new Error("企业名称最少4个汉字"));
    } else {
      callback();
    }
  };

  const enterpriseCodeVail = (rule: any, value: any, callback: any) => {
    if (value === "") {
      callback(new Error("请填写统一信用代码"));
    } else if (!/^[A-Za-z0-9]{18}$/.test(value)) {
      callback(new Error("统一信用代码为18位数，大小字母和数字组成"));
    } else {
      callback();
    }
  };

  useEffect(() => {
    getGetcaptcha();
  }, []);

  return (
    <div className="min-w-[1200] bg-[#FFFFFF] relative h-[100vh] min-h-[750] flex flex-col">
      <Header></Header>
      <div className={`w-[100%] relative mt-[48] ${registerLess.registerForm}`}>
        <div className="w-[400] mx-[auto]">
          <div className="text-center t-[24] t-[#192530] leading-[28] tracking-[4] mb-[24] ">
            注册
          </div>
          {showAlert && (
            <div className="pb-[16]">
              <Alert
                showIcon
                closable
                message={alert}
                type={alertType}
                afterClose={handleClose}
                icon={<i className="icon t-[16]">&#xe664;</i>}
                className="!px-[16] !py-[8] !border-[0]"
              />
            </div>
          )}
          <Form
            size="large"
            form={useForm}
            onFinish={handlerSubmint}
            onValuesChange={onValuesChange}
          >
            <Form.Item>
              <Form.Item
                noStyle={true}
                name="enterpriseName"
                rules={[
                  {
                    required: true,
                    validator: (rule, value, callback) =>
                      enterpriseNameVail(rule, value, callback),
                  },
                ]}
              >
                <Input placeholder="*企业名称" autoComplete="off" />
              </Form.Item>
              <p className="absolute t-[12] t-[#a1a1a1] leading-[20] right-[0]">
                请与营业执照保持一致
              </p>
            </Form.Item>
            <Form.Item>
              <Form.Item
                noStyle={true}
                name="socialCreditCode"
                rules={[
                  {
                    required: true,
                    validator: (rule, value, callback) =>
                      enterpriseCodeVail(rule, value, callback),
                  },
                ]}
              >
                <Input placeholder="*统一社会信用代码" autoComplete="off" />
              </Form.Item>
              <p className="absolute t-[12] t-[#a1a1a1] leading-[20] right-[0]">
                请与营业执照保持一致
              </p>
            </Form.Item>
            <div className="flex justify-between">
              <Form.Item
                name="name"
                className="!w-[164]"
                rules={[
                  {
                    required: true,
                    message: "请填写经办人姓名",
                  },
                ]}
              >
                <Input placeholder="*请填写经办人姓名" autoComplete="off" />
              </Form.Item>
              <Form.Item
                name="mobile"
                className="!w-[226]"
                rules={[
                  { required: true, message: "请填写手机号" },
                  {
                    pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
                    message: "请填写手机号",
                  },
                ]}
              >
                <Input
                  placeholder="*请填写经办人手机号"
                  autoComplete="new-mobile"
                />
              </Form.Item>
            </div>
            <Form.Item>
              <Form.Item
                noStyle={true}
                name="password"
                rules={[
                  {
                    required: true,
                    validator: (rules, value, callback) =>
                      passwordVail(rules, value, callback),
                  },
                  {},
                ]}
              >
                <Input.Password
                  placeholder="*请设置登录密码"
                  autoComplete="new-password"
                />
              </Form.Item>
              <DgPaswIntensity value={password} />
            </Form.Item>
            <Form.Item>
              <Row gutter={8}>
                <Col span={17}>
                  <Form.Item
                    noStyle={true}
                    name="catchcode"
                    rules={[{ required: true, message: "请输入图形码" }]}
                  >
                    <Input placeholder="请输入图形码" />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <img
                    src={imgCode}
                    height={48}
                    className={registerLess.imgCode}
                    onClick={() => getGetcaptcha()}
                    alt=""
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Form.Item
                noStyle
                name="smsCode"
                rules={[{ required: true, message: "请输入短信验证码" }]}
              >
                <Input
                  placeholder="短信验证码"
                  suffix={
                    <span
                      className={registerLess.suffix}
                      onClick={handlerSendCode}
                    >
                      {countdown ? `${count}/s` : "发送短信"}
                    </span>
                  }
                />
              </Form.Item>
              {showPrompt && (
                <div className="absolute bg-[#F0F2F5] w-[246] h-[22] rounded-[4] leading-[22] top-[50] right-[0] t-[12] text-center">
                  <span className="t-[#303133]" style={{ zoom: "0.9" }}>
                    验证码已发送给{phoneMobile}，10分钟内有效
                  </span>
                </div>
              )}
            </Form.Item>
            <Form.Item
              name="isProtocol"
              valuePropName="checked"
              className={registerLess.top_25}
            >
              <Checkbox>
                同意
                <a href="/protocol" target="_blank" className="aui-C8010B">
                  《用户协议》
                </a>
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                className={registerLess.register_button}
              >
                注册
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <DgCopyright style={{ marginTop: "auto" }} paddingBottom="30px" />
    </div>
  );
};
