import { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import dgCardLess from "./css/dg-card.less";

interface propsType {
  title: React.ReactNode;
  titleLfet?: React.ReactNode;
  opers?: React.ReactNode;
  children?: React.ReactNode;
  showExpanded?: boolean;
}

export default (props: propsType) => {
  const { title, titleLfet, opers, children, showExpanded = false } = props;

  const [isExpand, setIsExpand] = useState(true);

  return (
    <div className="w-[100%] p-[20] bg-[#FFFFFF] rounded-[4] relative ">
      <div className="flex pb-[10] items-center ">
        <p
          className={`${dgCardLess.title} t-[18] t-[#303133] relative text-left pl-[10] mr-[20] flex items-center`}
        >
          {title}
          {showExpanded && (
            <span>
              {isExpand ? (
                <DownOutlined
                  style={{
                    fontSize: "14px",
                    color: "#0048FF",
                    marginLeft: "5px",
                  }}
                  onClick={() => setIsExpand(false)}
                />
              ) : (
                <UpOutlined
                  style={{
                    fontSize: "14px",
                    color: "#0048FF",
                    marginLeft: "5px",
                  }}
                  onClick={() => setIsExpand(true)}
                />
              )}
            </span>
          )}
        </p>
        {titleLfet}
      </div>
      {opers}
      {isExpand ? children : null}
    </div>
  );
};
