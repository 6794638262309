import { Button, DatePicker } from "antd";
import moment from "moment";
import Header from "../components/header";
import homeLess from "./home.less";
import { useState, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import { Chart } from "@antv/g2";
import userheadimg from "@/assets/img/userheadimg.png";
import ajax from "../assets/js/ajax";
import DgCopyright from "../components/dg-copyright";

let chart: any = null;
export default memo(() => {
  const navigate = useNavigate();
  let [info, setInfo] = useState({
    realName: "",
    balance: 0,
  });
  let [financialInstitution, setFinancialInstitution] = useState({
    statusName: "",
  });
  let [time, setTime] = useState<string>(moment().format("YYYY"));

  useEffect(() => {
    getMonthData();
  }, [time]);

  /**
   * @author 伟健 / 张
   * @name 初始化 图表
   * @param
   * @return
   */
  let initChart = (data: any[]) => {
    chart.data(data);
    let maxNum = Math.max.apply(null, [
      ...data.map((el: any) => {
        return el.consumeAmount;
      }),
      ...data.map((el: any) => {
        return el.callNum;
      }),
    ]);
    chart.scale(
      {
        callNum: {
          min: 0,
          max: maxNum + 10,
        },
        consumeAmount: {
          min: 0,
          max: maxNum + 10,
        },
      },
      {
        nice: true,
      }
    );
    chart.axis("consumeAmount", false);
    chart.legend({
      custom: true,
      items: [
        {
          name: "调用次数",
          value: "callNum",
          marker: {
            symbol: "line",
            style: { stroke: "#1890ff", lineWidth: 2 },
          },
        },
        {
          name: "消费金额",
          value: "consumeAmount",
          marker: {
            symbol: "line",
            style: { stroke: "#2fc25b", lineWidth: 2 },
          },
        },
      ],
    });

    chart.line().position("time*callNum").color("#1890ff");
    chart.line().position("time*consumeAmount").color("#2fc25b");

    chart.removeInteraction("legend-filter"); // 自定义图例，移除默认的分类图例筛选交互
    chart.render();
  };

  /**
   * @author 伟健 / 张
   * @name 当前用户信息
   * @param
   * @return
   */
  let getCurrentInfo = () => {
    ajax.post({
      url: "/openPlatform/user/current",
      data: {},
      self: this,
      success: (res) => {
        localStorage.setItem("current", JSON.stringify(res));
        setInfo(res.user || {});
        setFinancialInstitution(res.financialInstitution || {});
      },
      fail: () => {
        navigate("/");
      },
    });
  };
  /**
   * @author 伟健 / 张
   * @name 统计数据
   * @param
   * @return
   */
  let getMonthData = () => {
    ajax.get({
      url: "/openPlatform/homePageData/homePageMonthData",
      params: {
        year: time,
      },
      self: this,
      success: (res) => {
        console.log(res.table);
        initChart(res.table);
      },
      fail: () => {},
    });
  };
  /**
   * @author 伟健 / 张
   * @name 手动更新
   * @param
   * @return
   */
  let updateData = () => {
    ajax.get({
      url: "/openPlatform/homePageData/updateData",
      data: {},
      self: this,
      success: (res) => {
        getMonthData();
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    // if (hasPermission("openPlatform:homePageData:homePageMonthData")) {
    chart = new Chart({
      container: "chart",
      autoFit: true,
      height: 500,
      padding: [30, 20, 70, 30],
    });
    // }
    getCurrentInfo();
    getMonthData();
  }, []);

  return (
    <div className="w-[100vw] h-[100vh] min-h-[750] bg-[#F7F8FC]">
      <div className="relative w-[100%] h-[100%]">
        <Header info={info}></Header>
        <div className={homeLess.wrap}>
          <div className="w-[1200] mx-[auto] flex justify-between pb-[30]">
            <div className={`${homeLess.chart} px-[30]`}>
              <div className="flex justify-between pt-[30] px-[10]">
                <div className="">
                  <span>数据概况</span>
                  <span
                    className="aui-link pl-[6]"
                    onClick={() => updateData()}
                  >
                    更新
                  </span>
                </div>
                <div>
                  <DatePicker
                    defaultValue={moment(time, "YYYY")}
                    onChange={(time, timeString) => {
                      setTime(timeString);
                    }}
                    picker="year"
                  />
                </div>
              </div>
              <div id="chart"></div>
            </div>
            <div className={homeLess.account}>
              <div className={homeLess.accountInfo}>
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <img
                      src={userheadimg}
                      alt=""
                      className={homeLess.userHeadimg}
                    />{" "}
                    <span className="pl-[6]">{info.realName || "--"}</span>
                  </div>
                  <div>
                    <p
                      onClick={() => {
                        navigate("/admin");
                      }}
                      className={homeLess.userCenter}
                    >
                      用户中心
                    </p>
                  </div>
                </div>
                <div className="pt-[10]">
                  <i className="icon"></i> 企业状态：{" "}
                  <span>{financialInstitution.statusName || "--"}</span>
                </div>
              </div>
              <div className="pt-[30] pb-[26] pl-[16] pr-[16] aui-bg-white">
                <p className="t-[14] aui-999CA1">当前可用额度(元)</p>
                <div className="flex justify-between items-center pt-[18]">
                  <div>
                    <span className="t-[24] font-600 aui-color-main">
                      {info.balance}
                    </span>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        navigate("/admin/recharge");
                      }}
                      type="primary"
                    >
                      充值
                    </Button>
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  navigate("/admin/product");
                }}
                className={`mt-[12] pt-[30] pr-[16] pl-[16] ${homeLess.box_bg}`}
              >
                <div className="flex justify-between">
                  <div className="flex">
                    <div>
                      <p className={homeLess.icon_box}>
                        <i className="icon t-[26] aui-C8010B">&#xe61b;</i>
                      </p>
                    </div>
                    <div className="pl-[10]">
                      <p className="t-[14] aui-192530">产品中心</p>
                      <p className="t-[12] pt-[4] aui-757575">
                        OFD文件生成、企业信息查询
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <i className="icon">&#xe646;</i>
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  navigate("/admin/order");
                }}
                className={`mt-[12] pt-[30] pr-[16] pl-[16] ${homeLess.box_bg}`}
              >
                <div className="flex justify-between">
                  <div className="flex">
                    <div>
                      <p className={homeLess.icon_box}>
                        <i className="icon t-[26] aui-C8010B">&#xe602;</i>
                      </p>
                    </div>
                    <div className="pl-[10]">
                      <p className="t-[14] aui-192530">我的订单</p>
                      <p className="t-[12] pt-[4] aui-757575">
                        OFD文件生成、企业信息查询
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <i className="icon">&#xe646;</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DgCopyright paddingTop="0px" paddingBottom="0px" />
      </div>
    </div>
  );
});
