import { Table, Pagination } from "antd";
import { memo, useEffect, useState } from "react";
import ajax from "../../../assets/js/ajax";
import type { ColumnsType } from "antd/es/table";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import emptyPng from "../../../assets/img/empty.png";

let navigate: NavigateFunction | null = null;

interface DataType {
  key: React.Key;
  title: string;
  dataIndex: string;
  align: string;
}

interface PropType {
  realEnterpriseId: string | number;
}

export default (props: PropType) => {
  navigate = useNavigate();
  const { realEnterpriseId } = props;
  const params = useLocation().state as {
    tradeNo: string;
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "账款编号",
      key: "accountNo",
      dataIndex: "accountNo",
      align: "center",
      width: 260,
    },
    {
      title: "销售合同编号",
      dataIndex: "saleContractNo",
      key: "saleContractNo",
      align: "center",
    },
    {
      title: "账款金额(元)",
      dataIndex: "accountAmount",
      key: "accountAmount",
      align: "center",
    },
    {
      title: "销售日期",
      dataIndex: "saleDate",
      key: "saleDate",
      align: "center",
    },
    {
      title: "账款到期时间",
      dataIndex: "accountDueDate",
      key: "accountDueDate",
      align: "center",
    },
    {
      title: "核心企业名称",
      dataIndex: "coreEnterpriseName",
      key: "coreEnterpriseName",
      align: "center",
    },
    {
      title: "融资状态",
      dataIndex: "assetStatusName",
      key: "assetStatusName",
      align: "center",
    },
    {
      title: "确权状态",
      dataIndex: "confirmStatusName",
      key: "confirmStatusName",
      align: "center",
    },
    {
      title: "支付状态",
      dataIndex: "paymentStatusName",
      key: "paymentStatusName",
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "",
      key: "oper",
      align: "center",
      width: 60,
      fixed: "right",
      render: (text, record: any) => {
        return (
          <span
            className="aui-link pl-[4]"
            onClick={() => {
              navigate!("/admin/supply/debtDetail", {
                state: { id: record.id },
              });
            }}
          >
            查看
          </span>
        );
      },
    },
    //
  ];

  const [totalItems, setTotalItems] = useState<number>(0);

  const [data, setData] = useState<any>([]);

  const [isEmpty, setIsEmpty] = useState(true);

  const [formData, setFormData] = useState<any>({
    pageNo: 1, // 页数
    pageSize: 10, // 条数
  });

  const handelPageChange = (pageNo: number, pageSize: number) => {
    setFormData({
      pageNo,
      pageSize,
    });
  };

  const getList = () => {
    ajax.post({
      url: "/openPlatform/accountsReceivableFinancing/accountAmountPage",
      data: { ...params, realEnterpriseId, ...formData },
      self: this,
      success: (res) => {
        if (res.result) {
          setData(() => {
            return res.result.map((e: any, i: number) => {
              return { ...e, key: i };
            });
          });
        }
        setTotalItems(res.totalItems);
        setIsEmpty(res.totalItems <= 0 ? true : false);
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getList();
  }, [formData]);

  return (
    <div className="w-[100%] h-[100%]">
      <div className="t-[14] t-[#BEBEBE] pt-[10] pb-[20] ">
        注：仅支持在融资期限内查询，融资期限过后，将关闭入口。可查询提交融资申请前180天的历史账款和融资期间新增账款。
      </div>
      {isEmpty ? (
        <div className="text-center my-[130] mx-[auto]">
          <img src={emptyPng} alt="" />
          <p className="mt-[28] t-[16] t-[#909399]">
            融资期限已结束，不支持查询该企业账款信息
          </p>
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            pagination={false}
            dataSource={data}
            scroll={{ x: 1100 }}
          />
          <div className="text-center pt-[24]">
            <Pagination
              onChange={handelPageChange}
              total={totalItems}
              showTotal={(total) => `总条数 ${total}`}
            ></Pagination>
          </div>
        </div>
      )}
    </div>
  );
};
