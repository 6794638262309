import Dgupload from "../../components/dg-upload";
import { Button, Form, Input, Modal, Table, message } from "antd";
import proceedingDetailLess from "./css/detail.less";
import { memo, useEffect, useState } from "react";
import Dglist from "../../components/dg-list";
import Dglistitem from "../../components/dg-list-item";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
import { downloadFile } from "../../assets/js/util";
import { ColumnsType } from "antd/es/table";

let navigate: NavigateFunction | null = null;

export default memo(() => {
  navigate = useNavigate();
  const params = useLocation().state as {
    tradeNo: string;
    open: boolean;
  };

  const [data, setData] = useState<any>({});

  // 上传合同
  const [contractVisible, setContractVisible] = useState<boolean>(false);
  const [contractForm] = Form.useForm();
  const [contractUrl, setContractUrl] = useState<string>("");
  //提交上传合同
  const handlerSubmitContract = (values: any) => {
    ajax.post({
      url: "/openPlatform/accidentPreventionOrder/uploadContract",
      data: { contractUrl, tradeNo: params.tradeNo },
      self: this,
      success: (res) => {
        if (res.code == 200) {
          message.success("上传成功");
          getDetail();
          setContractVisible(false);
        }
      },
      fail: () => {},
    });
  };

  // 票信息
  const [invoiceVisible, setInvoiceVisible] = useState<boolean>(false);
  const [invoiceForm] = Form.useForm();
  //提交票信息
  const handlerSubmitInvoice = (values: any) => {
    ajax.post({
      url: "/openPlatform/safeLiabilityInvoice/apply",
      data: { ...values, tradeNo: params.tradeNo },
      self: this,
      success: (res) => {
        if (res.code == 200) {
          message.success("信息已提交给安评公司，开票完成后回到当前页下载");
          getDetail();
          setInvoiceVisible(false);
        }
      },
      fail: () => {},
    });
  };

  // 服务内容详情
  const [serviceVisible, setServiceVisible] = useState<boolean>(false);
  interface DataType {
    key: string;
    count: number;
    description: string;
    serviceContent: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "服务内容名称",
      dataIndex: "serviceContent",
      key: "serviceContent",
      align: "center",
    },
    {
      title: "说明",
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: "次数(次)",
      dataIndex: "count",
      key: "count",
      align: "center",
    },
  ];

  const [tableData, setTableData] = useState<DataType[]>([]);

  const getDetail = () => {
    ajax.post({
      url: "/openPlatform/accidentPreventionOrder/detail",
      data: params,
      self: this,
      success: (res) => {
        setData(res || {});
        if (res.serviceContent.length > 0) {
          setTableData(res.serviceContent);
        }
        if (params.open && res.status == 5) {
          setContractVisible(true);
        }
      },
      fail: () => {},
    });
  };

  const goCheck = (t: string) => {
    navigate!("/admin/accidentService", {
      state: { tradeNo: data.tradeNo, activeTab: t },
    });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <div>
      <div className="pb-[12]">
        <span className="t-[16] aui-192530 pr-[4]">
          事故预防服务订单详情
        </span>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>服务项目</p>
        <div className={proceedingDetailLess.opers}>
          {data.status == 5 && (
            <Button type="primary" onClick={() => setContractVisible(true)}>
              上传合同
            </Button>
          )}
          {((data.type == 0 && !data.applyInvoice) &&
            [5, 6, 7, 8].includes(+data.status)) && (
              <Button type="primary" onClick={() => setInvoiceVisible(true)}>
                开票申请
              </Button>
            )}
        </div>
        <div className={proceedingDetailLess.checklinks}>
          <Dglist column={2} labelWidth="200px">
            <Dglistitem
              labelChildren={
                <div className={proceedingDetailLess.checkitem}>隐患排查：</div>
              }
            >
              <span className="aui-link" onClick={() => goCheck("1")}>
                去查看{">"}
              </span>
            </Dglistitem>
            <Dglistitem
              labelChildren={
                <div className={proceedingDetailLess.checkitem}>风险评估：</div>
              }
            >
              <span className="aui-link" onClick={() => goCheck("2")}>
                去查看{">"}
              </span>
            </Dglistitem>
            <Dglistitem
              labelChildren={
                <div className={proceedingDetailLess.checkitem}>其他文件：</div>
              }
            >
              <span className="aui-link" onClick={() => goCheck("3")}>
                去查看{">"}
              </span>
            </Dglistitem>
          </Dglist>
          {/* <div
            className={proceedingDetailLess.checkitem}
            onClick={() => goCheck("1")}
          >
            隐患排查 &nbsp; <span className="aui-link">去查看{">"}</span>
          </div>
          <div
            className={proceedingDetailLess.checkitem}
            onClick={() => goCheck("2")}
          >
            风险评估 &nbsp; <span className="aui-link">去查看{">"}</span>
          </div>
          <div
            className={proceedingDetailLess.checkitem}
            onClick={() => goCheck("3")}
          >
            其他文件 &nbsp; <span className="aui-link">去查看{">"}</span>
          </div> */}
        </div>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>订单信息</p>
        {data.type == 0 && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="服务订单编号">{data.tradeNo || "-"}</Dglistitem>
            <Dglistitem label="状态">{data.statusDesc || "-"}</Dglistitem>
            <Dglistitem label="服务费用(元)">
              <span className={proceedingDetailLess.amount__color}>
                {data.amount || "-"}
              </span>
            </Dglistitem>
            <Dglistitem label="审核备注">{data.auditRemark || "-"}</Dglistitem>
            <Dglistitem label="提交时间">{data.gmtCreate || "-"}</Dglistitem>
            <Dglistitem label="支付时间">
              {data.payCallbackTime || "-"}
            </Dglistitem>
            <Dglistitem label="电子发票">
              <div>
                {data.invoiceUrl ? (
                  <span>
                    <a href={data.invoiceUrl} target="_blank">
                      电子发票
                    </a>

                    <span
                      className="aui-link pl-[6]"
                      onClick={() => downloadFile(data.invoiceUrl, "电子发票")}
                    >
                      下载
                    </span>
                  </span>
                ) : (
                  "-"
                )}
              </div>
            </Dglistitem>
          </Dglist>
        )}
        {data.type == 1 && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="服务订单编号">{data.tradeNo || "-"}</Dglistitem>
            <Dglistitem label="状态">{data.statusDesc || "-"}</Dglistitem>
            <Dglistitem label="服务费用(元)">
              <span className={proceedingDetailLess.amount__color}>
                {data.amount || "-"}
              </span>
            </Dglistitem>
            <Dglistitem label="提交时间">{data.gmtCreate || "-"}</Dglistitem>
          </Dglist>
        )}
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>服务详情</p>
        {data.type == 0 && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="服务名称">{data.name || "-"}</Dglistitem>
            <Dglistitem label="安评公司名称">
              {data.safetyEnterpriseName || "-"}
            </Dglistitem>
            <Dglistitem label="服务开始,结束时间">
              {data.startTime && data.insureEffectTimeEnd
                ? `${data.startTime}~${data.insureEffectTimeEnd}`
                : "-"}
            </Dglistitem>
            <Dglistitem label="服务内容">
              <span
                className="aui-link"
                onClick={() => setServiceVisible(true)}
              >
                查看详情
              </span>
            </Dglistitem>
            <Dglistitem label="服务方案">
              <div>
                {data.plan ? (
                  <span>
                    <a href={data.plan} target="_blank">
                      查看
                    </a>

                    <span
                      className="aui-link pl-[6]"
                      onClick={() => downloadFile(data.plan, "服务方案")}
                    >
                      下载
                    </span>
                  </span>
                ) : (
                  "-"
                )}
              </div>
            </Dglistitem>
            <Dglistitem label="服务合同">
              <div>
                {data.contract ? (
                  <span>
                    <a href={data.contract} target="_blank">
                      查看
                    </a>
                    <span
                      className="aui-link pl-[6]"
                      onClick={() => downloadFile(data.contract, "服务合同")}
                    >
                      下载
                    </span>
                  </span>
                ) : (
                  "-"
                )}
              </div>
            </Dglistitem>
          </Dglist>
        )}
        {data.type == 1 && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="服务名称">{data.name}</Dglistitem>
            <Dglistitem label="服务开始,结束时间">
              {data.startTime && data.insureEffectTimeEnd
                ? `${data.startTime}~${data.insureEffectTimeEnd}`
                : "-"}
            </Dglistitem>
            <Dglistitem label="服务内容">
              <span
                className="aui-link"
                onClick={() => setServiceVisible(true)}
              >
                查看详情
              </span>
            </Dglistitem>
            <Dglistitem label="服务方案">
              <div>
                {data.plan ? (
                  <span>
                    <a href={data.plan} target="_blank">
                      查看
                    </a>

                    <span
                      className="aui-link pl-[6]"
                      onClick={() => downloadFile(data.plan, "服务方案")}
                    >
                      下载
                    </span>
                  </span>
                ) : (
                  "-"
                )}
              </div>
            </Dglistitem>
            <Dglistitem label="服务形式">{data.typeDesc}</Dglistitem>
          </Dglist>
        )}
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>项目信息</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="项目名称">{data.projectName || "-"}</Dglistitem>
          <Dglistitem label="项目类型">
            {data.projectTypeDesc || "-"}
          </Dglistitem>
          <Dglistitem label="开工,竣工时间">
            {data.projectStartTime && data.projectEndTime
              ? `${data.projectStartTime}~${data.projectEndTime}`
              : "-"}
          </Dglistitem>
          <Dglistitem label="生产经营地址">
            {data.productionAddress || "-"}
          </Dglistitem>
          <Dglistitem label="施工企业名称">
            {data.constructionUnitName || "-"}
          </Dglistitem>
          <Dglistitem label="统一社会信用代码">
            {data.constructionUnitUnifyCreditCode || "-"}
          </Dglistitem>
          <Dglistitem label="经办人姓名">
            {data.constructionUnitRealName || "-"}
          </Dglistitem>
          <Dglistitem label="经办人手机号">
            {data.constructionUnitRealMobile || "-"}
          </Dglistitem>
        </Dglist>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>安责险信息</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="保函编号">{data.insureOrderNo || "-"}</Dglistitem>
          <Dglistitem label="保险期限">
            {data.insureEffectTimeStart && data.insureEffectTimeEnd
              ? `${data.insureEffectTimeStart}~${data.insureEffectTimeEnd}`
              : "-"}
          </Dglistitem>
          <Dglistitem label="投保人数(人)">
            {data.insurePerson || "-"}
          </Dglistitem>
          <Dglistitem label="累计限额(元)">
            <span className={proceedingDetailLess.amount__color}>
              {data.cumulativeLimit || "-"}
            </span>
          </Dglistitem>
        </Dglist>
      </div>
      <div className="text-center mt-[32]">
        <Button
          type="default"
          className="w-160"
          onClick={() => {
            navigate!("/admin/accidentOrder");
          }}
        >
          返回
        </Button>
      </div>

      {/* 上传合同弹窗 */}
      <Modal
        title="上传合同"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setContractVisible(false)}
        open={contractVisible}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          form={contractForm}
          onFinish={handlerSubmitContract}
        >
          <Form.Item
            label="事故预防服务合同(盖章)"
            hasFeedback
            name="contractUrl"
            rules={[
              {
                required: true,
                message: "请上传事故预防服务合同",
              },
            ]}
          >
            <div>
              <div className={proceedingDetailLess.suffixBox}>
                请上传事故预防服务合同(盖章)，支持.pdf格式，大小不超过50M
              </div>
              <Dgupload
                fileName="事故预防服务合同"
                button={true}
                type={[".pdf"]}
                value={contractUrl}
                setValue={setContractUrl}
              ></Dgupload>
            </div>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setContractVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* 开票信息弹窗 */}
      <Modal
        centered
        width={700}
        footer={false}
        title="开票信息"
        forceRender={true}
        maskClosable={true}
        open={invoiceVisible}
        onCancel={() => setInvoiceVisible(false)}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={invoiceForm}
          onFinish={handlerSubmitInvoice}
        >
          <Form.Item
            label="发票抬头"
            name="invoiceHead"
            rules={[{ required: true, message: "请输入发票抬头" }]}
          >
            <Input placeholder="请输入发票抬头" />
          </Form.Item>
          <Form.Item
            label="税号"
            name="unifyCreditCode"
            rules={[{ required: true, message: "请输入税号" }]}
          >
            <Input placeholder="请输入税号" />
          </Form.Item>
          <Form.Item
            label="联系电话"
            name="recipientPhone"
            rules={[
              { required: true, message: "请输入联系电话" },
              {
                pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
                message: "请输入联系电话",
              },
            ]}
          >
            <Input maxLength={11} placeholder="请输入联系电话" />
          </Form.Item>
          <Form.Item
            label="电子邮件"
            name="email"
            rules={[
              { required: true, message: "请输入电子邮件" },
              {
                pattern:
                  /^[a-z\d]+(\.[a-z\d]+)*@([\da-z](-[\da-z])?)+(\.{1,2}[a-z]+)+$/,
                message: "请输入电子邮件",
              },
            ]}
          >
            <Input placeholder="请输入电子邮件" />
          </Form.Item>
          <Form.Item label="开户支行" name="bankDot">
            <Input placeholder="请输入开户支行" />
          </Form.Item>
          <Form.Item label="银行账户" name="bankCardNo">
            <Input placeholder="请输入银行账户" />
          </Form.Item>
          <Form.Item label="企业地址" name="addr">
            <Input placeholder="请输入企业地址" />
          </Form.Item>
          <Form.Item
            label="企业电话"
            name="linkPhone"
            rules={[
              {
                pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
                message: "请输入企业电话",
              },
            ]}
          >
            <Input placeholder="请输入企业电话" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setInvoiceVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* 服务内容详情弹窗 */}
      <Modal
        centered
        width={700}
        open={serviceVisible}
        footer={false}
        title="服务内容详情"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setServiceVisible(false)}
      >
        <Table pagination={false} columns={columns} dataSource={tableData} />
      </Modal>
    </div>
  );
});
