import Dgform from "../../components/dg-form";
import { Table, Button, Form, Pagination } from "antd";
import type { FormInstance } from "antd";
import { memo, useEffect, useRef, useState } from "react";
import { itmeType } from "../../types";
import ajax from "../../assets/js/ajax";
import type { ColumnsType } from "antd/es/table";
import { NavigateFunction, useNavigate } from "react-router-dom";
import EmptyPage from "../../components/emptyPage";
import { hasPermission } from "../../assets/js/hasPermission";

let navigate: NavigateFunction | null = null;

interface DataType {
  key: React.Key;
  title: string;
  dataIndex: string;
  align: string;
}

export default memo(() => {
  navigate = useNavigate();

  const columns: ColumnsType<DataType> = [
    {
      title: "企业名称",
      key: "coreEnterpriseName",
      dataIndex: "coreEnterpriseName",
      align: "center",
    },
    {
      title: "统一社会信用代码",
      dataIndex: "coreEnterpriseCreditCode",
      key: "coreEnterpriseCreditCode",
      align: "center",
    },
    {
      title: "法人姓名",
      dataIndex: "coreEnterpriseLegalPerson",
      key: "coreEnterpriseLegalPerson",
      align: "center",
    },
    {
      title: "企业地址",
      dataIndex: "coreEnterpriseAddress",
      key: "coreEnterpriseAddress",
      align: "center",
    },
    {
      title: "经办人姓名",
      dataIndex: "coreEnterpriseOperator",
      key: "coreEnterpriseOperator",
      align: "center",
    },
    {
      title: "经办人手机号",
      dataIndex: "coreEnterpriseOperatorMobile",
      key: "coreEnterpriseOperatorMobile",
      align: "center",
    },
    {
      title: "签约时间",
      dataIndex: "gmtCreate",
      key: "gmtCreate",
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "",
      key: "oper",
      align: "center",
      width: 60,
      fixed: "right",
      render: (text, record: any) => {
        return (
          <span
            className="aui-link pl-[4]"
            onClick={() => {
              navigate!("/admin/supply/coreDetail", {
                state: { id: record.id },
              });
            }}
          >
            查看
          </span>
        );
      },
    },
    //
  ];

  const [totalItems, setTotalItems] = useState<number>(0);
  const [formItems, setFormItems] = useState<itmeType[]>([
    {
      label: "企业名称",
      type: "input",
      key: "coreEnterpriseName",
      des: "请输入企业名称",
    },
    {
      label: "统一社会信用代码",
      type: "input",
      key: "coreEnterpriseCreditCode",
      des: "请输入统一社会信用代码",
    },
    {
      label: "法人姓名",
      type: "input",
      key: "coreEnterpriseLegalPerson",
      des: "请输入法人姓名",
    },
    {
      label: "签约时间",
      type: "date",
      model: [],
      key: "gmtCreateBegin,gmtCreateEnd",
      des: ["提交开始", "提交结束"],
    },
  ]);

  const [data, setData] = useState<any>([]);

  const [formData, setFormData] = useState<any>({
    pageNo: 1, // 页数
    pageSize: 10, // 条数
    sortDirection: "", // 排序方向
    sortField: "", // 排序字段
    coreEnterpriseLegalPerson: "", // 状态
    coreEnterpriseName: "", // 订单编号
    coreEnterpriseCreditCode: "",
  });

  const formRef = useRef<{
    getFormRef: () => FormInstance<any>;
    getValues: () => any;
  }>();

  const handelSeach = (values: any) => {
    setFormData({
      pageNo: 1,
      pageSize: 10,
      ...values,
    });
  };

  const handelPageChange = (pageNo: number, pageSize: number) => {
    setFormData({
      pageNo,
      pageSize,
      ...formRef.current?.getValues(),
    });
  };

  const getList = (data?: any) => {
    ajax.post({
      url: "/openPlatform/supply/chainSignContract/page",
      data: Object.assign(formData, data || {}),
      self: this,
      success: (res) => {
        setData(() => {
          return res.result.map((e: any, i: number) => {
            return { ...e, key: i };
          });
        });
        setTotalItems(res.totalItems);
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    if (hasPermission("openPlatform:supply:chainSignContract:page")) {
      getList();
    }
  }, [formData]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {hasPermission("openPlatform:supply:chainSignContract:page") ? (
        <div>
          <div className="pb-[12]">
            <span className="t-[16] t-[#192530] pr-[4]">已签约核心企业</span>
          </div>
          <div className="bg-[#FFFFFF] min-h-[80vh]">
            <div className="border-b-[3] border-[#f7f8fc] border-solid  p-[26]">
              <Dgform ref={formRef} items={formItems} handelSeach={handelSeach}>
                <Form.Item className="ml-[auto] mr-[0]">
                  <Button type="primary" htmlType="submit">
                    搜索
                  </Button>
                </Form.Item>
              </Dgform>
            </div>
            <div className="p-[26]">
              <Table
                columns={columns}
                pagination={false}
                dataSource={data}
                scroll={{ x: 1100 }}
              />
              <div className="text-center pt-[24]">
                <Pagination
                  onChange={handelPageChange}
                  total={totalItems}
                  showTotal={(total) => `总条数 ${total}`}
                ></Pagination>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
});
