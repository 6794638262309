import Dglist from "../../components/dg-list";
import Dglistitem from "../../components/dg-list-item";
import DgcountDown from "../../components/dg-countDown";
import EmptyPage from "../../components/emptyPage";
import indexLess from "./css/index.less";

import { Button, Modal, Form, Input, message, Row, Col } from "antd";
import { useState, useEffect, memo, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
import { hasPermission } from "../../assets/js/hasPermission";

/* 
 
*/
export default memo(() => {
  const navigate = useNavigate();
  const [phoneVisible, setPhoneVisible] = useState(false);
  const [isModifyPhone, setIsModifyPhone] = useState(false);
  let [imgCode, setImgCode] = useState("");
  let [refForm] = Form.useForm();
  const [data, setData] = useState({
    loginName: "",
    authStatusName: "",
    mobile: "",
    email: "",
    idCardNo: "",
    realName: "",
  });

  /**
   * @author 伟健 / 张
   * @name 账号信息
   * @param
   * @return
   */
  let getAccountInfo = () => {
    ajax.post({
      url: "/openPlatform/user/current?t=account",
      data: {},
      self: this,
      success: (res) => {
        setData(res.user || {});
        refForm.setFieldsValue(res.user || {});
      },
      fail: () => {},
    });
  };
  /**
   * @author 伟健 / 张
   * @name 修改经办人信息
   * @param
   * @return
   */
  let handlerSubmint = (values: any) => {
    ajax.post({
      url: "/openPlatform/user/editAuth",
      data: values,
      self: this,
      success: (res) => {
        console.log(res);
        if (res) {
          message.success("修改成功");
          setPhoneVisible(false);
          getAccountInfo();
        }
      },
      fail: () => {},
    });
  };
  /**
   * @author 伟健 / 张
   * @name 图形码
   * @param
   * @return
   */
  let getGetcaptcha = () => {
    ajax.post({
      url: "/openPlatform/common/getcaptcha",
      data: {},
      self: this,
      success: (res) => {
        if (res) {
          setImgCode(res);
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author 伟健 / 张
   * @name 发送短信数据校验
   * @param
   * @return
   */
  let checkSmsCodeData = () => {
    let form = refForm.getFieldsValue(["imgCode", "mobile"]);
    let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;

    if (!reg.test(form.mobile)) {
      message.warning("请输入手机号");
      return false;
    }
    if (!form.imgCode) {
      message.warning("请输入图形码");
      return false;
    }
    return true;
  };

  /**
   * @author 伟健 / 张
   * @name 发送短信
   * @param
   * @return
   */
  let handlerSendCode = () => {
    let form = refForm.getFieldsValue(["imgCode", "mobile"]);
    return new Promise((resolve, reject) => {
      ajax.post({
        url: "/openPlatform/common/sendSmsCode",
        data: { catchcode: form.imgCode, mobile: form.mobile },
        self: this,
        success: (res) => {
          getGetcaptcha();
          resolve(res);
        },
        fail: () => {
          getGetcaptcha();
          reject();
        },
      });
    });
  };
  useEffect(() => {
    // if (hasPermission("openPlatform:user")) {
    getAccountInfo();
    getGetcaptcha();
    // }
  }, []);

  // const HandlePage = () => withAuth( <IndexPage />, "openPlatform:user:current");

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="">
        <div className="pb-[12]">
          <span className="t-[16] aui-192530 pr-[4]">
            账户信息
          </span>{" "}
          <span className="aui-788593 t-[16]">
            请完善以下信息，以帮助您更好地使用平台功能
          </span>
        </div>
        <div className={`aui-bg-white ${indexLess.wrap}`}>
          <div
            className={`pl-[26] pr-[26] pb-[20] pt-[20] ${indexLess.bor_bttom}`}
          >
            <Dglist column={2} labelWidth="140px">
              <Dglistitem label="账户名">{data.loginName}</Dglistitem>
              <Dglistitem label="">
                <div className="text-right">
                  <Button
                    onClick={() => {
                      navigate("/admin/modifyPasw");
                    }}
                  >
                    修改密码
                  </Button>
                </div>
              </Dglistitem>
            </Dglist>
          </div>
          <div className=" pl-[26] pr-[26] pb-[20] pt-[20]">
            <div className="flex justify-between pb-[16]">
              <div>
                <span
                  className={`t-[18] aui-192530 ${indexLess.title}`}
                >
                  经办人信息
                </span>
              </div>
              <div>
                <p className="aui-inline">
                  <Button
                    onClick={() => {
                      setPhoneVisible(true);
                      setIsModifyPhone(false);
                    }}
                  >
                    完善信息
                  </Button>
                </p>
                <p className="aui-inline pl-[10]">
                  <Button
                    onClick={() => {
                      setPhoneVisible(true);
                      setIsModifyPhone(true);
                    }}
                  >
                    修改手机号
                  </Button>
                </p>
              </div>
            </div>
            <Dglist column={2} labelWidth="140px">
              <Dglistitem label="经办人状态">{data.authStatusName}</Dglistitem>
              <Dglistitem label="身份证号">{data.idCardNo}</Dglistitem>
              <Dglistitem label="姓名">{data.realName}</Dglistitem>
              <Dglistitem label="邮箱">{data.email}</Dglistitem>
              <Dglistitem label="手机号">{data.mobile}</Dglistitem>
            </Dglist>
          </div>
        </div>

        <Modal
          title="经办人"
          maskClosable={true}
          onCancel={() => setPhoneVisible(false)}
          open={phoneVisible}
          footer={false}
          centered
          width={700}
        >
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 10 }}
            form={refForm}
            onFinish={handlerSubmint}
          >
            <Form.Item
              label="姓名"
              name="realName"
              rules={[{ required: true, message: "请输入姓名" }]}
            >
              <Input placeholder="请输入姓名" />
            </Form.Item>
            <Form.Item
              label="身份证号码"
              name="idCardNo"
              rules={[{ required: true, message: "请输入身份证号码" }]}
            >
              <Input placeholder="请输入身份证号码" />
            </Form.Item>
            <Form.Item
              label="邮件"
              name="email"
              rules={[
                { required: true, message: "请输入邮件" },
                {
                  pattern:
                    /^[a-z\d]+(\.[a-z\d]+)*@([\da-z](-[\da-z])?)+(\.{1,2}[a-z]+)+$/,
                  message: "请输入邮件",
                },
              ]}
            >
              <Input placeholder="请输入邮件" />
            </Form.Item>

            {/* <Form.Item
				label="手机号"
				name="mobile"
				rules={[
						{ required: isModifyPhone, message: "请输入手机号" },
						{ pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/, message: "请输入手机号" },
				]}
		>
				<Form.Item name="mobile" className="aui-inline mb-[0]">
						<Input className="w-170" maxLength={11} disabled={!isModifyPhone} placeholder="请输入手机号" />
				</Form.Item>
				<Button className="ml-[10]" onClick={() => setIsModifyPhone(!isModifyPhone)}>
						{isModifyPhone ? "返 回" : "修改手机"}
				</Button>
		</Form.Item> */}

            <Form.Item
              label="手机号"
              name="mobile"
              rules={[
                { required: isModifyPhone, message: "请输入手机号" },
                {
                  pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
                  message: "请输入手机号",
                },
              ]}
            >
              <Row gutter={8}>
                <Col span={16}>
                  <Form.Item
                    className="aui-inline mb-[0]"
                    name="mobile"
                  >
                    <Input
                      className="w-170"
                      maxLength={11}
                      disabled={!isModifyPhone}
                      placeholder="请输入手机号"
                    />
                  </Form.Item>
                </Col>
                <Col span={8} className="text-right">
                  <Button
                    className=""
                    onClick={() => setIsModifyPhone(!isModifyPhone)}
                  >
                    {isModifyPhone ? "返 回" : "修改手机"}
                  </Button>
                </Col>
              </Row>
            </Form.Item>

            {isModifyPhone && (
              <Form.Item
                label="图片验证码"
                name="imgCode"
                rules={[{ required: isModifyPhone, message: "请输入图形码" }]}
              >
                <Row gutter={8}>
                  <Col span={15}>
                    <Form.Item
                      name="imgCode"
                      className="aui-inline mb-[0]"
                    >
                      <Input placeholder="图片验证码" />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <img
                      src={imgCode}
                      className={indexLess.imgCode}
                      onClick={() => getGetcaptcha()}
                      alt=""
                    />
                  </Col>
                </Row>
              </Form.Item>
            )}
            {isModifyPhone && (
              <Form.Item
                label="短信验证码"
                name="catchCode"
                rules={[
                  { required: isModifyPhone, message: "请输入短信验证码" },
                ]}
              >
                <Row gutter={8}>
                  <Col span={15}>
                    <Form.Item
                      name="catchCode"
                      className="aui-inline mb-[0]"
                    >
                      <Input placeholder="请输入短信验证码" />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <DgcountDown
                      id="account"
                      before={checkSmsCodeData}
                      cb={handlerSendCode}
                    >
                      发送短信码
                    </DgcountDown>
                  </Col>
                </Row>
              </Form.Item>
            )}

            <Form.Item wrapperCol={{ offset: 6 }}>
              <Button type="primary" className="w-150" htmlType="submit">
                确 定
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
      {/* {hasPermission("openPlatform:user") ? (
        
      ) : (
        <EmptyPage />
      )} */}
    </div>
    // hasPermission("openPlatform:user:current") &&
    // !hasPermission("openPlatform:user:current") && <EmptyPage/>
  );
});
