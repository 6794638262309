import { memo, useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  DatePicker,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Col,
  Modal,
  Tooltip,
  InputNumber,
} from "antd";
import NumberUpDown from "./components/NumberUpDown";
import Dgupload from "../../components/dg-upload";
import moment from "moment";
import configureLess from "./css/configure.less";
import ajax from "../../assets/js/ajax";

const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD";

let navigate: NavigateFunction | null = null;

export default memo(() => {
  navigate = useNavigate();
  const params = useLocation().state as {
    type: string;
    tradeNo: string;
    safeLiabilityTradeNo: string;
    insureEffectTimeStart: string;
    insureEffectTimeEnd: string;
  };

  const [formRef] = Form.useForm();

  // const [frequency, setFrequency] = useState<string | number | null>(0);
  const [plan, setPlan] = useState<string>("");
  const [type, setType] = useState();
  const [showModal, setShowModal] = useState<boolean>(false);

  const [services, setServices] = useState<any[]>([]);
  const [safetyEnterpriseName, setSafetyEnterpriseName] = useState<string>("");
  const [safetyEnterpriseId, setSafetyEnterpriseId] = useState<string>("");
  // const [serviceTime, setServiceTime] = useState<string>("");
  const [startTime, setStartTime] = useState<string>(
    params.insureEffectTimeStart
  );
  const [safetyEnterprise, setSafetyEnterprise] = useState<any[]>([]);

  // 提交表单
  const onFinish = (values: any) => {
    const serviceContent = services
      .map((item) => {
        return { serviceContentId: item.id, count: item.times };
      })
      .filter((item) => item.count > 0);
    ajax.post({
      url: "/openPlatform/accidentPreventionOrder/apply",
      data: {
        safeLiabilityTradeNo: params.safeLiabilityTradeNo,
        name: values.name,
        amount: values.amount,
        plan,
        startTime,
        type,
        safetyEnterpriseId,
        serviceContent: JSON.stringify(serviceContent),
        tradeNo: params.tradeNo,
      },
      self: this,
      success: (res) => {
        message.success(type == 1 ? "服务订单创建成功，请按约提供服务" : "已提交给安评公司，请耐心等待审核结果");
        navigate!("/admin/accidentOrder");
      },
      fail: () => {},
    });
  };

  // 单选框事件
  const onRadioChange = (e: RadioChangeEvent) => {
    setType(e.target.value);
  };

  const getList = () => {
    ajax.post({
      url: "/openPlatform/safeLiabilityServiceContent/list",
      data: { tradeNo: params.safeLiabilityTradeNo },
      self: this,
      success: (res) => {
        const result = res.map((item: any) => {
          return {
            ...item,
            times: 1,
          };
        });
        setServices([...result]);
        // setTotalItems(res.totalItems);
      },
      fail: () => {},
    });
  };

  // 获取安评公司
  const getSafetyEnterprise = () => {
    ajax.post({
      url: "/openPlatform/safetyEnterprise/list",
      data: {},
      self: this,
      success: (res) => {
        setSafetyEnterprise([...res]);
      },
      fail: () => {},
    });
  };

  const handleServTime = (dates: any, dateStrings: any) => {
    const time = dateStrings[0];
    setStartTime(time);
  };

  const handleChangeNum = (t: number, idx: number) => {
    let newServices: any[] = [];
    services.forEach((item) => {
      if (item.id == idx) {
        item.times = t;
      }
      newServices.push(item);
    });
    setServices([...newServices]);
  };

  const choiceCom = (v: any) => {
    setSafetyEnterpriseId(v.id);
    setSafetyEnterpriseName(v.enterpriseName);
  };

  const getDetail = async () => {
    let result = await ajax.post({
      url: "/openPlatform/safeLiabilityServiceContent/list",
      data: { tradeNo: params.safeLiabilityTradeNo },
      self: this,
      success: (res) => {},
    });
    ajax.post({
      url: "/openPlatform/accidentPreventionOrder/detail",
      data: { tradeNo: params.tradeNo },
      self: this,
      success: (res) => {
        formRef.setFieldsValue({
          name: res.name,
          plan: res.plan,
          type: res.type,
          safetyEnterpriseName: res.safetyEnterpriseName,
        });
        setStartTime(res.startTime);
        setSafetyEnterpriseId(res.safetyEnterpriseId);
        setSafetyEnterpriseName(res.safetyEnterpriseName);
        setPlan(res.plan);
        setType(res.type);
        const services = (result as any).data;
        const arrs = services.map((item: any) => {
          return {
            ...item,
            times: res.serviceContent.length
              ? res.serviceContent.find(
                  (curr: any) => curr.serviceContentId == item.id
                )?.count || 1
              : 1,
          };
        });
        setServices([...arrs]);
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    // if (hasPermission("openPlatform:purchaseContract:list")) {
    if (params.type == "create") {
      getSafetyEnterprise();
      getList();
    }

    if (params.type == "edit") {
      getDetail();
    }
    // }
  }, []);

  return (
    <div className={configureLess.configure}>
      <div className={configureLess.title}>事故预防服务配置</div>
      <Form
        form={formRef}
        preserve={false}
        labelCol={{ span: 5, offset: 1 }}
        wrapperCol={{ span: 12 }}
        onFinish={onFinish}
      >
        <Form.Item
          label="服务名称"
          name="name"
          rules={[{ required: true, message: "请输入服务名称!" }]}
        >
          <Input placeholder="请输入服务名称" />
        </Form.Item>

        <Form.Item
          label="服务开始时间、结束时间"
          rules={[
            {
              required: true,
              message: "请选择服务开始时间、结束时间!",
            },
            {
              validator: (_, value) =>
                startTime
                  ? Promise.resolve()
                  : Promise.reject(new Error("请选择服务开始时间!")),
            },
          ]}
        >
          <RangePicker
            defaultValue={[
              moment(params.insureEffectTimeStart, dateFormat),
              moment(params.insureEffectTimeEnd, dateFormat),
            ]}
            disabled={[false, true]}
            onChange={handleServTime}
          />
        </Form.Item>

        <div className={configureLess.table}>
          <div className={configureLess.head}>
            <div className={configureLess.item}>服务内容名称</div>
            <div className={configureLess.item}>描述</div>
            <div className={configureLess.item}>*次数(次)</div>
          </div>
          {services.length > 0 &&
            services.map((item: any) => {
              return (
                <div className={configureLess.tableRow} key={item.id}>
                  <div className={configureLess.tableCol}>
                    <div className={configureLess.tableColContent}>
                      {item.serviceContent}
                    </div>
                  </div>
                  <div className={configureLess.tableCol}>
                    <Tooltip title={item.description}>
                      <div className={configureLess.tableColContent}>
                        {item.description}
                      </div>
                    </Tooltip>
                  </div>
                  <div className={configureLess.tableCol}>
                    <NumberUpDown
                      min={0}
                      precision={0}
                      value={item.times}
                      onChange={(v) => {
                        handleChangeNum(v as number, item.id);
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>

        <Form.Item
          label="事故预防服务方案"
          name="plan"
          rules={[{ required: true, message: "请上传事故预防服务方案!" }]}
        >
          <div>
            <div className={configureLess.suffixBox}>
              请上传一份50M以内文件，支持PDF格式
            </div>
            <Dgupload
              fileName="事故预防服务方案"
              type={[".pdf"]}
              setValue={setPlan}
              value={plan}
              button={true}
            ></Dgupload>
          </div>
        </Form.Item>

        <Form.Item
          label="事故预防服务形式"
          name="type"
          rules={[{ required: true, message: "请选择事故预防服务形式!" }]}
        >
          <Radio.Group
            value={type}
            onChange={onRadioChange}
            disabled={params.type == "edit"}
          >
            <Radio value={0}>委托第三方机构</Radio>
            <Radio value={1}>内部机构执行</Radio>
          </Radio.Group>
        </Form.Item>

        {type == 0 && (
          <Form.Item
            label="安评公司"
            name="safetyEnterpriseName"
            rules={[
              {
                required: true,
                message: "请选择安评公司!",
                validator: (_, value) =>
                  safetyEnterpriseName
                    ? Promise.resolve()
                    : Promise.reject(new Error("请选择安评公司!")),
              },
            ]}
          >
            <Row gutter={10}>
              <Col span={19}>
                <Input
                  value={safetyEnterpriseName}
                  placeholder="请选择安评公司"
                  disabled
                />
              </Col>
              <Col span={5}>
                <Button
                  onClick={() => setShowModal(true)}
                  disabled={params.type == "edit"}
                >
                  选泽安评公司
                </Button>
              </Col>
            </Row>
          </Form.Item>
        )}

        {type == 1 && (
          <Form.Item
            label="服务费用(元)"
            name="amount"
            rules={[{ required: true, message: "请输入服务费用(元)!" }]}
          >
            <InputNumber
              min={0}
              precision={2}
              defaultValue={0}
              disabled={params.type == "edit"}
              style={{ width: "100%" }}
            />
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            block
            type="primary"
            htmlType="submit"
            style={{ width: "120px" }}
          >
            提交申请
          </Button>
        </Form.Item>
      </Form>

      <Modal
        centered
        width={760}
        footer={false}
        open={showModal}
        forceRender={true}
        maskClosable={true}
        title="选泽安评公司"
        onCancel={() => setShowModal(false)}
      >
        <div className={configureLess.modalContent}>
          <div className={configureLess.companyBox}>
            {safetyEnterprise.length > 0 &&
              safetyEnterprise.map((item) => {
                return (
                  <div
                    className={configureLess.company}
                    key={item.id}
                    onClick={() => choiceCom(item)}
                    style={{
                      borderColor:
                        safetyEnterpriseId == item.id
                          ? "var(--main-color)"
                          : "#ededed",
                    }}
                  >
                    <p>{item.enterpriseName}</p>
                    <div className={configureLess.content}>
                      <Tooltip title={item.introduce}>
                        <p className={configureLess.txt}>{item.introduce}</p>
                      </Tooltip>
                    </div>
                  </div>
                );
              })}
          </div>
          <div style={{ margin: "20px auto 0", width: "fit-content" }}>
            <Button
              type="primary"
              block
              style={{ width: "120px" }}
              onClick={() => setShowModal(false)}
            >
              确定
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
});
