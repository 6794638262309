import * as React from "react";
import itemLess from "./css/dg-list-item.less";
interface propsType {
  children?: React.ReactNode;
  labelChildren?: React.ReactNode;
  column?: number;
  labelWidth?: string;
  label?: String;
  suffix?: String;
}

export default (props: propsType) => {
  let {
    children,
    column = 2,
    labelWidth = "260px",
    label,
    suffix = "：",
    labelChildren,
  } = props;
  let width = `${100 / column}%`;

  return (
    <div
      style={{ width: width }}
      className="flex mt-[6]  mb-[4]"
    >
      <div
        style={{ flexBasis: labelWidth }}
        className={`text-right ${itemLess.label}`}
      >
        {labelChildren}
        {label}
        {label ? suffix : ""}
      </div>
      <div className={itemLess.value}>{children}</div>
    </div>
  );
};
