import { Table, Tooltip, Pagination, message } from "antd";
import indexLess from "./css/secretkey.less";
import type { ColumnsType } from "antd/es/table";

import EmptyPage from "../../components/emptyPage";
import { hasPermission } from "../../assets/js/hasPermission";

import React, { useState, useEffect, memo } from "react";
// import { useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
interface DataType {
  key: React.Key;
  title: string;
  dataIndex: string;
}

let handlerCopy = (val: string) => {
  // let remark = `备注：${this.payInfo.payRemark} \n`;
  let ipt = document.getElementById("copyTxt") as HTMLInputElement;
  ipt.value = val;
  ipt.select();
  document.execCommand("copy");
  message.success("复制成功");
};

const columns: ColumnsType<DataType> = [
  {
    title: "APPID",
    dataIndex: "appId",
    key: "appId",
    render: (text) => (
      <div className="flex items-center">
        <Tooltip
          placement="topLeft"
          className="w-200 aui-inline aui-row aui-ellipsis-1"
          title={text}
        >
          {text}
        </Tooltip>
        <div className="aui-inline pl-[8]">
          <span
            className="cursor-pointer aui-C8010B"
            onClick={() => handlerCopy(text)}
          >
            复制
          </span>
        </div>
      </div>
    ),
  },

  {
    title: "Access Key",
    dataIndex: "appKey",
    key: "appKey",

    render: (text) => (
      <div className="flex items-center">
        <Tooltip
          placement="topLeft"
          className="w-200 aui-inline aui-row aui-ellipsis-1"
          title={text}
        >
          {text}
        </Tooltip>
        <div className="aui-inline pl-[8]">
          <span
            className="cursor-pointer aui-C8010B"
            onClick={() => handlerCopy(text)}
          >
            复制
          </span>
        </div>
      </div>
    ),
  },
  {
    title: "Access Secret",
    dataIndex: "appSecret",
    key: "appSecret",

    render: (text) => (
      <div className="flex items-center">
        <Tooltip
          placement="topLeft"
          className="w-200 aui-inline aui-row aui-ellipsis-1"
          title={text}
        >
          {text}
        </Tooltip>
        <div className="aui-inline pl-[8]">
          <span
            className="cursor-pointer aui-C8010B"
            onClick={() => handlerCopy(text)}
          >
            复制
          </span>
        </div>
      </div>
    ),
  },
];

export default memo(() => {
  let [data, setData] = useState<any>([]);
  let [totalItems, setTotalItems] = useState<number>(0);
  interface orderPage {
    pageNo: number | null; // 页数
    pageSize: number | null; // 条数
  }
  let [formData, setFormData] = useState<orderPage>({
    pageNo: 1, // 页数
    pageSize: 10, // 条数
  });

  /**
   * @author 伟健 / 张
   * @name 列表
   * @param
   * @return
   */
  let getList = () => {
    ajax.post({
      url: "/openPlatform/openSecretKey/page",
      data: {},
      self: this,
      success: (res) => {
        setData(() => {
          return res.result.map((e: any, i: number) => {
            return { ...e, key: i };
          });
        });
        setTotalItems(res.totalItems);
      },
      fail: () => {},
    });
  };
  /**
   * @author 伟健 / 张
   * @name 分页
   * @param
   * @return
   */

  let handelPageChange = (pageNo: number, pageSize: number) => {
    setFormData({
      pageNo,
      pageSize,
    });
  };
  useEffect(() => {
    if (hasPermission("openPlatform:openSecretKey:page")) {
      getList();
    }
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {hasPermission("openPlatform:openSecretKey:page") ? (
        <div className="">
          <div className="pb-[12]">
            <span className="t-[16] aui-192530 pr-[4]">
              密匙管理
            </span>
          </div>
          <div className={`aui-bg-white ${indexLess.wrap} p-[26]`}>
            <Table columns={columns} pagination={false} dataSource={data} />
            <div className="text-center pt-[24]">
              <Pagination
                onChange={handelPageChange}
                total={totalItems}
                showTotal={(total) => `总条数 ${total}`}
              ></Pagination>
            </div>
          </div>
          <textarea
            id="copyTxt"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              opacity: 0,
              zIndex: -10,
            }}
          ></textarea>
        </div>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
});
