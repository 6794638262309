import { Button, Form, Input, InputNumber, Modal, Image, message } from "antd";
import DgCard from "../../../components/dg-card";
import detailLess from "./css/detail.less";
import Dglist from "../../../components/dg-list";
import Dglistitem from "../../../components/dg-list-item";
import Dgupload from "../../../components/dg-upload";
import idCardFront from "../../../assets/img/idCardFront.png";
import idCardBack from "../../../assets/img/idCardBack.png";
import FileList from "../../../components/dg-fileList";
import { useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
// import ajax from "../../../assets/js/ajax";
import { downloadFile } from "../../../assets/js/util";
import ajax from "../../../assets/js/ajax";

interface propsType {
  data: any;
  chainAccountData: any;
  coreEnterpriseData: any;
  onRefreshData: Function;
  documentGroups: any[];
}

let navigate: NavigateFunction | null = null;

export default (props: propsType) => {
  const {
    data = {},
    chainAccountData = {},
    coreEnterpriseData = {},
    documentGroups = [],
    onRefreshData,
  } = props;
  navigate = useNavigate();
  const params = useLocation().state as {
    tradeNo: string;
  };

  // const [data, setData] = useState<any>({});
  // const [chainAccountData, setChainAccountData] = useState<any>({});
  // const [coreEnterpriseData, setCoreEnterpriseData] = useState<any>({});
  // 接受申请
  const [acceptForm] = Form.useForm();
  const [acceptModal, setAcceptModal] = useState(false);
  const [
    accountsReceivableTransferAgreement,
    setAccountsReceivableTransferAgreement,
  ] = useState("");

  // 审核通过
  const [examineForm] = Form.useForm();
  const [examineModal, setExamineModal] = useState(false);
  const [financingAgreement, setFinancingAgreement] = useState("");

  // 拒绝申请
  const [refuseForm] = Form.useForm();
  const [refuseModal, setRefuseModal] = useState(false);

  // 审核不通过
  const [failForm] = Form.useForm();
  const [failModal, setFailModal] = useState(false);

  // 上传放款凭证
  const [loanForm] = Form.useForm();
  const [loanModal, setLoanModal] = useState(false);
  const [loanFile, setLoanFile] = useState("");

  // 收款异常
  const [abnormalForm] = Form.useForm();
  const [abnormalModal, setAbnormalModal] = useState(false);

  // 打包下载
  const handleBatch = () => {
    ajax.post({
      url: "/openPlatform/accountsReceivableFinancing/downloadPackage",
      data: {
        tradeNo: params.tradeNo,
      },
      self: this,
      success: (res) => {
        if (res) {
          window.open(res);
        }
      },
      fail: () => {},
    });
  };

  // 确认收款
  const handleOnTime = () => {
    Modal.confirm({
      title: "是否足额到账",
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        ajax.post({
          url: "/openPlatform/accountsReceivableFinancing/checkStatus",
          data: { ...params, status: 10 },
          self: this,
          success: (res) => {
            if (res) {
              message.success("操作成功");
              onRefreshData();
            }
          },
          fail: () => {},
        });
      },
    });
  };

  // 接受申请
  const handlerSubmitAccep = (values: any) => {
    ajax.post({
      url: "/openPlatform/accountsReceivableFinancing/checkStatus",
      data: {
        ...params,
        ...values,
        status: 4,
        accountsReceivableTransferAgreement,
      },
      self: this,
      success: (res) => {
        if (res) {
          message.success("操作成功");
          setAcceptModal(false);
          // getDetail();
          onRefreshData();
        }
      },
      fail: () => {},
    });
  };

  // 审核通过
  const handlerSubmitExamine = (values: any) => {
    ajax.post({
      url: "/openPlatform/accountsReceivableFinancing/checkStatus",
      data: { ...params, ...values, status: 6, financingAgreement },
      self: this,
      success: (res) => {
        if (res) {
          message.success("操作成功");
          setExamineModal(false);
          // getDetail();
          onRefreshData();
        }
      },
      fail: () => {},
    });
  };

  // 拒绝申请
  const handlerSubmitRefuse = (values: any) => {
    ajax.post({
      url: "/openPlatform/accountsReceivableFinancing/checkStatus",
      data: { ...params, ...values, status: 12 },
      self: this,
      success: (res) => {
        if (res) {
          message.success("操作成功");
          setRefuseModal(false);
          onRefreshData();
        }
      },
      fail: () => {},
    });
  };

  // 审核不通过
  const handlerSubmitFail = (values: any) => {
    ajax.post({
      url: "/openPlatform/accountsReceivableFinancing/checkStatus",
      data: { ...params, ...values, status: 12 },
      self: this,
      success: (res) => {
        if (res) {
          message.success("操作成功");
          setFailModal(false);
          onRefreshData();
        }
      },
      fail: () => {},
    });
  };

  // 上传放款凭证
  const handlerSubmitLoan = (values: any) => {
    ajax.post({
      url: "/openPlatform/accountsReceivableFinancing/uploadLoanVoucher",
      data: { ...params, ...values, url: loanFile },
      self: this,
      success: (res) => {
        if (res) {
          message.success("操作成功");
          setLoanModal(false);
          onRefreshData();
        }
      },
      fail: () => {},
    });
  };

  // 是否小于30天
  const isWithin30Days = (date1: string | number | Date) => {
    if (!date1) return false;
    // 创建两个日期对象（如果输入的是日期字符串，需要先转换为日期对象）
    const d1 = new Date(date1);
    const d2 = new Date();
    // 计算时间差（以毫秒为单位）
    const timeDiff = Math.abs(Number(d1) - Number(d2));
    // 将时间差转换为天数
    const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    // 判断天数差是否小于或等于30天
    return dayDiff <= 30;
  };

  // 是否待还款并且时间小于30天
  const isLessRepayment = (status: any, time: string) => {
    return status == 8 && isWithin30Days(time);
  };

  // 收款异常
  const handlerSubmitAbnormal = (values: any) => {
    ajax.post({
      url: "/openPlatform/accountsReceivableFinancing/checkStatus",
      data: { ...params, ...values, status: 11 },
      self: this,
      success: (res) => {
        if (res) {
          message.success("操作成功");
          setAbnormalModal(false);
          onRefreshData();
        }
      },
      fail: () => {},
    });
  };

  return (
    <div>
      <DgCard
        title="订单状态"
        opers={
          <div
            className={`w-[210] flex flex-wrap justify-end absolute right-[20] top-[20] ${detailLess.opers}`}
          >
            {data.status == 3 && (
              <Button
                danger
                type="primary"
                onClick={() => setRefuseModal(true)}
              >
                拒绝申请
              </Button>
            )}
            {data.status == 3 && (
              <Button type="primary" onClick={() => setAcceptModal(true)}>
                接受申请
              </Button>
            )}

            {data.status == 5 && (
              <Button type="primary" danger onClick={() => setFailModal(true)}>
                审核不通过
              </Button>
            )}
            {data.status == 5 && (
              <Button type="primary" onClick={() => setExamineModal(true)}>
                审核通过
              </Button>
            )}

            {data.status == 7 && (
              <Button type="primary" onClick={() => setLoanModal(true)}>
                上传放款凭证
              </Button>
            )}

            {data.status == 9 && (
              <Button
                type="primary"
                danger
                onClick={() => setAbnormalModal(true)}
              >
                收款异常
              </Button>
            )}

            {[9, 11].includes(+data.status) && (
              <Button type="primary" onClick={handleOnTime}>
                确认收账
              </Button>
            )}
          </div>
        }
      >
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="订单编号">{data.tradeNo || "-"}</Dglistitem>
          <Dglistitem label="金融产品">
            {data.productTypeName || "-"}
          </Dglistitem>
          <Dglistitem label="订单状态">
            <span
              style={{
                color: isLessRepayment(
                  data.status,
                  chainAccountData.accountDueDate as string
                )
                  ? "#f56c6c"
                  : "",
              }}
            >
              {data.statusName || "-"}
            </span>
          </Dglistitem>
          <Dglistitem label="状态更新时间">{data.gmtCreate || "-"}</Dglistitem>
          <Dglistitem label="审核备注">{data.auditResult || "-"}</Dglistitem>
          <Dglistitem label="还款凭证">
            {data.repaymentVoucher ? (
              <span
                className="t-[#0048FF] cursor-pointer"
                onClick={() => downloadFile(data.repaymentVoucher, "还款凭证")}
              >
                下载
              </span>
            ) : (
              <span>-</span>
            )}
          </Dglistitem>
          <Dglistitem label="收账确认结果">
            {data.collectionConfirmationResult || "-"}
          </Dglistitem>
        </Dglist>
      </DgCard>
      {data.status > 5 && (
        <DgCard title="额度信息">
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="实际额度(万元)">
              {data.financingAmount || "-"}
            </Dglistitem>
            <Dglistitem label="利率(%)">{data.rate || "-"}</Dglistitem>
            <Dglistitem label="利息(元)">{data.interest || "-"}</Dglistitem>
            <Dglistitem label="融资协议">
              {data.financingAgreement ? (
                <span
                  className="t-[#0048FF] cursor-pointer"
                  onClick={() =>
                    downloadFile(data.financingAgreement, "融资协议")
                  }
                >
                  下载
                </span>
              ) : (
                <span>-</span>
              )}
            </Dglistitem>
            <Dglistitem label="融资协议签订时间">
              {data.financingAgreementSigningTime || "-"}
            </Dglistitem>
            <Dglistitem label="放款凭证">
              {data.loanVoucher ? (
                <span
                  className="t-[#0048FF] cursor-pointer"
                  onClick={() => downloadFile(data.loanVoucher, "放款凭证")}
                >
                  下载
                </span>
              ) : (
                <span>-</span>
              )}
            </Dglistitem>
          </Dglist>
        </DgCard>
      )}
      <DgCard title="融资申请信息" showExpanded={true}>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="申请额度(万元)">
            {data.applicationAmount || "-"}
          </Dglistitem>
          <Dglistitem label="申请期限">
            {data.financingTimeStart && data.financingTimeEnd
              ? `${data.financingTimeStart}~${data.financingTimeEnd}`
              : "-"}
          </Dglistitem>
          <Dglistitem label="融资用途">
            {data.financingPurpose || "-"}
          </Dglistitem>
        </Dglist>
      </DgCard>
      <DgCard title="账款信息" showExpanded={true}>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="账款编号">
            {chainAccountData.accountNo || "-"}
          </Dglistitem>
          <Dglistitem label="当前债权人">
            {chainAccountData.currentDebtorName || "-"}
          </Dglistitem>
          <Dglistitem label="销售合同编号">
            {chainAccountData.saleContractNo || "-"}
          </Dglistitem>
          <Dglistitem label="账款金额(元)">
            {chainAccountData.accountAmount || "-"}
          </Dglistitem>
          <Dglistitem label="销售日期">
            {chainAccountData.saleDate || "-"}
          </Dglistitem>
          <Dglistitem label="账款到期时间">
          <span
              style={{
                color: isLessRepayment(
                  data.status,
                  chainAccountData.accountDueDate as string
                )
                  ? "#f56c6c"
                  : "",
              }}
            >
              {chainAccountData.accountDueDate || "-"}
            </span>
          </Dglistitem>
          <Dglistitem label="电子债权凭证">
            {chainAccountData.elecDebtVoucher ? (
              <a
                className="t-[#0048FF] cursor-pointer"
                href={chainAccountData.elecDebtVoucher}
              >
                查看
              </a>
            ) : (
              <span>-</span>
            )}
          </Dglistitem>
          <Dglistitem label="确权时间">
            {chainAccountData.confirmDate || "-"}
          </Dglistitem>
          <Dglistitem label="销售合同">
            {chainAccountData.saleContract ? (
              <span
                className="t-[#0048FF] cursor-pointer"
                onClick={() =>
                  downloadFile(chainAccountData.saleContract, "销售合同")
                }
              >
                下载
              </span>
            ) : (
              <span>-</span>
            )}
          </Dglistitem>
          <Dglistitem label="发票">
            {chainAccountData.invoice ? (
              <span
                className="t-[#0048FF] cursor-pointer"
                onClick={() => downloadFile(chainAccountData.invoice, "发票")}
              >
                下载
              </span>
            ) : (
              <span>-</span>
            )}
          </Dglistitem>
          <Dglistitem label="发货凭证">
            {chainAccountData.deliveryVoucher ? (
              <span
                className="t-[#0048FF] cursor-pointer"
                onClick={() =>
                  downloadFile(chainAccountData.deliveryVoucher, "发货凭证")
                }
              >
                下载
              </span>
            ) : (
              <span>-</span>
            )}
          </Dglistitem>
          <Dglistitem label="其他交易文件">
            {chainAccountData.otherTransactionFile ? (
              <span
                className="t-[#0048FF] cursor-pointer"
                onClick={() =>
                  downloadFile(
                    chainAccountData.otherTransactionFile,
                    "其他交易文件"
                  )
                }
              >
                下载
              </span>
            ) : (
              <span>-</span>
            )}
          </Dglistitem>
          <Dglistitem label="账款转让协议">
            {data.financierTransferAgreement ? (
              <p>
                <a
                  className="t-[#0048FF] cursor-pointer"
                  href={data.financierTransferAgreement}
                >
                  查看
                </a>
                <span
                  className="t-[#0048FF] pl-[8] cursor-pointer"
                  onClick={() =>
                    downloadFile(
                      data.financierTransferAgreement,
                      "账款转让协议"
                    )
                  }
                >
                  下载
                </span>
              </p>
            ) : (
              <span>-</span>
            )}
          </Dglistitem>
          <Dglistitem label="账款转让签署时间">
            {data.transferSigningTime || "-"}
          </Dglistitem>
        </Dglist>
      </DgCard>
      <DgCard title="融资方信息" showExpanded={true}>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="企业名称">
            {data.upstreamEnterpriseName || "-"}
          </Dglistitem>
          <Dglistitem label="统一社会信用代码">
            {data.upstreamEnterpriseCode || "-"}
          </Dglistitem>
          <Dglistitem label="法人姓名">
            {data.upstreamLegalPerson || "-"}
          </Dglistitem>
          <Dglistitem label="法人身份证号">
            {data.upstreamLegalPersonIdCardNo || "-"}
          </Dglistitem>
          <Dglistitem label="经办人姓名">
            {data.upstreamOperatorName || "-"}
          </Dglistitem>
          <Dglistitem label="经办人手机号">
            {data.upstreamOperatorMobile || "-"}
          </Dglistitem>
          <Dglistitem label="身份证">
            <div className="flex">
              <Image
                width={126}
                height={95}
                src={data.upstreamLegalPersonIdCardFront || ""}
                fallback={idCardFront}
              />
              <Image
                width={126}
                height={95}
                src={data.upstreamLegalPersonIdCardReverse || ""}
                fallback={idCardBack}
                className="ml-[10]"
              />
            </div>
          </Dglistitem>
          <Dglistitem label="企业地址">{data.upstreamAddress}</Dglistitem>
          <Dglistitem label="企业简介">
            {data.upstreamEnterpriseName || "-"}
          </Dglistitem>
          <Dglistitem label="基本户开户行及支行">
            {data.upstreamBankDot || "-"}
          </Dglistitem>
          <Dglistitem label="基本户卡号">
            {data.upstreamBankCardNo || "-"}
          </Dglistitem>
        </Dglist>
      </DgCard>
      <DgCard title="采购方(核心企业)信息" showExpanded={true}>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="企业名称">
            {coreEnterpriseData.enterpriseName || "-"}
          </Dglistitem>
          <Dglistitem label="统一社会信用代码">
            {coreEnterpriseData.enterpriseCode || "-"}
          </Dglistitem>
          <Dglistitem label="法人姓名">
            {coreEnterpriseData.legalPersonName || "-"}
          </Dglistitem>
          <Dglistitem label="法人身份证号">
            {coreEnterpriseData.legalPersonIdCardNo || "-"}
          </Dglistitem>
          <Dglistitem label="经办人姓名">
            {data.coreEnterpriseOperatorName || "-"}
          </Dglistitem>
          <Dglistitem label="经办人手机号">
            {data.coreEnterpriseOperatorMobile || "-"}
          </Dglistitem>
          <Dglistitem label="企业地址">
            {coreEnterpriseData.address || "-"}
          </Dglistitem>
          <Dglistitem label="企业简介">
            {data.coreEnterpriseIntroduce || "-"}
          </Dglistitem>
          <Dglistitem label="基本户开户行及支行">
            {coreEnterpriseData.bankDot || "-"}
          </Dglistitem>
          <Dglistitem label="基本户卡号">
            {coreEnterpriseData.bankCardNo || "-"}
          </Dglistitem>
        </Dglist>
      </DgCard>
      <DgCard
        title="附件材料"
        showExpanded={true}
        opers={
          <div
            className={`w-[210] flex flex-wrap justify-end absolute right-[20] top-[20] ${detailLess.opers}`}
          >
            <Button type="primary" onClick={handleBatch}>
              打包下载
            </Button>
          </div>
        }
      >
        <div className="w-[100%]">
          {documentGroups.length > 0 &&
            documentGroups.map((item) => {
              return (
                <div className="w-[100%]" key={item}>
                  <div className="flex items-start pt-[10] t-[14] flex-wrap justify-end ">
                    <div className="w-[200] text-right t-[#788593]">
                      {item.typeName}：
                    </div>
                    <div className="flex-1 flex flex-col">
                      {item.attachments
                        .sort((a: any, b: any) => {
                          return (
                            new Date(b.time).getTime() -
                            new Date(a.time).getTime()
                          );
                        })
                        .map((curr: any, index: number) => {
                          return (
                            <FileList
                              {...curr}
                              files={curr.fileUrls}
                              key={index}
                            ></FileList>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </DgCard>

      {/* 接受申请 */}
      <Modal
        title="接受申请"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setAcceptModal(false)}
        open={acceptModal}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          form={acceptForm}
          onFinish={handlerSubmitAccep}
        >
          <Form.Item
            label="应收账款转让协议(盖章)"
            hasFeedback
            name="accountsReceivableTransferAgreement"
            rules={[
              {
                required: true,
                message: "请上传应收账款转让协议",
              },
            ]}
          >
            <div>
              <Dgupload
                key={Math.random()}
                fileName="应收账款转让协议"
                type={[".pdf"]}
                setValue={setAccountsReceivableTransferAgreement}
                value={accountsReceivableTransferAgreement}
                button={true}
              ></Dgupload>
              <div className={detailLess.suffixBox}>
                请上传应收账款转让协议(盖章)，支持.pdf格式文件，大小不超过10M
              </div>
            </div>
          </Form.Item>
          <Form.Item
            label="户名"
            name="financierAccountName"
            rules={[
              {
                required: true,
                message: "请输入户名",
              },
            ]}
          >
            <Input placeholder="请输入户名" />
          </Form.Item>
          <Form.Item
            label="开户行"
            name="financierAccountOpeningBank"
            rules={[
              {
                required: true,
                message: "请输入开户行",
              },
            ]}
          >
            <Input placeholder="请输入开户行" />
          </Form.Item>
          <Form.Item
            label="卡号"
            name="financierCardNumber"
            rules={[
              {
                required: true,
                message: "请输入卡号",
              },
            ]}
          >
            <Input placeholder="请输入卡号" />
          </Form.Item>
          <Form.Item label="审核备注" name="auditResult">
            <Input.TextArea rows={4} placeholder="请输入审核备注" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 7 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setAcceptModal(false)}
            >
              取消
            </Button>
            <Button type="primary" className="w-150 ml-[20]" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* 审核通过 */}
      <Modal
        title="审核通过"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setExamineModal(false)}
        open={examineModal}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={examineForm}
          onFinish={handlerSubmitExamine}
        >
          <Form.Item
            label="融资协议(盖章)"
            hasFeedback
            name="financingAgreement"
            rules={[
              {
                required: true,
                message: "请上传融资协议",
              },
            ]}
          >
            <div>
              <Dgupload
                key={Math.random()}
                fileName="融资协议"
                type={[".pdf"]}
                setValue={setFinancingAgreement}
                value={financingAgreement}
                button={true}
              ></Dgupload>
              <div className={detailLess.suffixBox}>
                请上传融资协议(盖章)，支持.pdf格式文件，大小不超过10M
              </div>
            </div>
          </Form.Item>
          <Form.Item
            label="实际额度(万元)"
            name="financingAmount"
            rules={[
              {
                required: true,
                message: "请输入实际额度",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              min={0}
              step={1}
              precision={0}
              controls={false}
              placeholder="请输入实际额度(万元)，支持整数"
            />
          </Form.Item>
          <Form.Item
            label="费率计算方式"
            name="rateCalculationMethod"
            initialValue="固定利率"
          >
            <Input disabled={true} placeholder="请输入费率计算方式" />
          </Form.Item>
          <Form.Item
            label="费率(%)"
            name="rate"
            rules={[
              {
                required: true,
                message: "请输入费率",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              min={0}
              step={0.01}
              precision={2}
              controls={false}
              placeholder="请输入费率(%)，支持精确到小数点后两位"
            />
          </Form.Item>
          <Form.Item label="审核备注" name="auditResult">
            <Input.TextArea rows={4} placeholder="请输入审核备注" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setExamineModal(false)}
            >
              取消
            </Button>
            <Button type="primary" className="w-150 ml-[20]" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* 拒绝申请 */}
      <Modal
        title="拒绝申请"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setRefuseModal(false)}
        open={refuseModal}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={refuseForm}
          onFinish={handlerSubmitRefuse}
        >
          <Form.Item label="审核备注" name="auditResult">
            <Input.TextArea rows={4} placeholder="请输入审核备注" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setRefuseModal(false)}
            >
              取消
            </Button>
            <Button type="primary" className="w-150 ml-[20]" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* 审核不通过 */}
      <Modal
        title="审核不通过"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setFailModal(false)}
        open={failModal}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={failForm}
          onFinish={handlerSubmitFail}
        >
          <Form.Item label="审核备注" name="auditResult">
            <Input.TextArea rows={4} placeholder="请输入审核备注" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setFailModal(false)}
            >
              取消
            </Button>
            <Button type="primary" className="w-150 ml-[20]" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* 上传放款凭证 */}
      <Modal
        title="上传放款凭证"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setLoanModal(false)}
        open={loanModal}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={loanForm}
          onFinish={handlerSubmitLoan}
        >
          <Form.Item
            label="融资方开户号"
            hasFeedback
            name="financingPartyAccountNumber"
            rules={[
              {
                required: true,
                message: "请输入融资方开户号",
              },
            ]}
          >
            <Input placeholder="请输入融资方开户号" />
          </Form.Item>
          <Form.Item
            label="放款凭证"
            hasFeedback
            name="loanFile"
            rules={[
              {
                required: true,
                message: "请上传放款凭证",
              },
            ]}
          >
            <div>
              <Dgupload
                key={Math.random()}
                fileName="放款凭证"
                type={[".pdf"]}
                setValue={setLoanFile}
                value={loanFile}
                button={true}
              ></Dgupload>
              <div className={detailLess.suffixBox}>
                请上传放款凭证，支持.pdf格式文件，大小不超过10M
              </div>
            </div>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setLoanModal(false)}
            >
              取消
            </Button>
            <Button type="primary" className="w-150 ml-[20]" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* 收款异常 */}
      <Modal
        title="未按时足额到账？"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setAbnormalModal(false)}
        open={abnormalModal}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={abnormalForm}
          onFinish={handlerSubmitAbnormal}
        >
          <Form.Item label="收款确认结果" name="collectionConfirmationResult">
            <Input.TextArea rows={4} placeholder="请输入收款确认结果" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setAbnormalModal(false)}
            >
              取消
            </Button>
            <Button type="primary" className="w-150 ml-[20]" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
